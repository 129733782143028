import { styled } from '@mui/system'

export const StyledGenericModalContainer = styled('div')({
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
})

export const StyledGenericModalContent = styled('div')(({ theme }) => ({
    maxWidth: 400,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white
}))
