import { styled } from '@mui/system'
import { Container, Typography } from '@mui/material'

export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(3.5)
}))
export const StyledStandardBg = styled('div')({
    boxShadow: '0px 3px 6px #00000029',
    width: '100%'
})

export const StyledLogo = styled('img')({
    width: '100%',
    maxWidth: 100
})

export const StyledRewards = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    width: '100%',
    gap: theme.spacing(1)
}))

export const StyledSearch = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[50]}`
}))

export const StyledSearchRewards = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
}))

export const StyledMyRewards = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    gap: theme.spacing(1)
}))

export const StyledProfile = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary
}))
export const StyledText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}))
