import { FC, FormEvent, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import GiftBoxIcon from 'assets/icons/giftbox-icon.svg'
import UsernameIcon from 'assets/icons/username-icon.svg'
import KeyIcon from 'assets/icons/password-icon.svg'
import logo from 'assets/icons/logoipsum-logo-32.png'

import {
    StyledCard,
    StyledCardContent,
    StyledCardHeader,
    StyledContainer,
    StyledForm,
    StyledLogo,
    StyledTitle,
    StyledWrapper
} from './login.styled'
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { content } from './login.cont'
import CommonTextField from 'components/global/commonTextField'
import useLoginForm from './useLoginForm'
import CommonButton from 'components/global/commonButton'
import CommonCheckbox from 'components/global/commonCheckbox'
import CommonTooltip from 'components/global/commonTooltip'
import { DocumentTitle } from 'components/global/documentTitle'
import { isAuthenticated } from 'helpers/auth'
import { useNavigate } from 'react-router-dom'
import CLIENT_CONFIG from 'configs'

const Login: FC = () => {
    const navigate = useNavigate()
    const matches = useMediaQuery('(max-width: 580px)')
    const {
        userInput,
        onFieldChange,
        onLogin,
        error,
        isTooltipErrorOpen,
        setTooltipErrorOpen,
        loading
    } = useLoginForm()

    const canLogin = (): boolean => {
        if (userInput.username && userInput.password) {
            return false
        }

        return true
    }

    useEffect(() => {
        const isLoggedIn = isAuthenticated()

        if (isLoggedIn) {
            navigate(`${process.env.PUBLIC_URL}/main`)
        }
    }, [navigate])

    return (
        <>
            <DocumentTitle title="Login" />
            <StyledContainer>
                <StyledWrapper>
                    <StyledLogo
                        src={CLIENT_CONFIG.PRIMARY_LOGO || logo}
                        alt="Rewards Microsite"
                    />
                    <StyledCard>
                        <StyledCardContent>
                            <StyledCardHeader>
                                <StyledTitle>
                                    <img
                                        src={GiftBoxIcon}
                                        alt={content.giftBoxIconAlt}
                                    />
                                    <Typography variant="h3">
                                        {content.title}
                                    </Typography>
                                </StyledTitle>
                                <Typography variant="subtitle1">
                                    {content.subtitle}
                                </Typography>
                            </StyledCardHeader>
                            <StyledForm>
                                <CommonTooltip
                                    onClick={() => setTooltipErrorOpen(false)}
                                    title="Invalid username or password"
                                    arrow
                                    placement={matches ? 'bottom-end' : 'right'}
                                    open={isTooltipErrorOpen}
                                    disableHoverListener={true}
                                >
                                    <div>
                                        <CommonTextField
                                            id="username"
                                            label="Username"
                                            value={userInput.username}
                                            disabled={loading}
                                            autoFocus
                                            onChange={onFieldChange}
                                            distanceBelow={1}
                                            icon={
                                                <img
                                                    src={UsernameIcon}
                                                    alt="User Icon"
                                                />
                                            }
                                            error={error}
                                            fullWidth={true}
                                        />
                                        <CommonTextField
                                            id="password"
                                            label="Password"
                                            type="password"
                                            value={userInput.password}
                                            disabled={loading}
                                            onChange={onFieldChange}
                                            icon={
                                                <img
                                                    src={KeyIcon}
                                                    alt="Key Icon"
                                                />
                                            }
                                            error={error}
                                            fullWidth={true}
                                        />
                                    </div>
                                </CommonTooltip>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    mb={3}
                                    mt={1}
                                >
                                    <Grid item>
                                        <CommonCheckbox
                                            label="Remember me"
                                            id="remember"
                                            checked
                                        />
                                    </Grid>
                                </Grid>
                                <CommonButton
                                    fullWidth
                                    disabled={canLogin() && !loading}
                                    loading={loading}
                                    onClick={(
                                        event: FormEvent<HTMLButtonElement>
                                    ) => {
                                        event.preventDefault()
                                        onLogin(event)
                                    }}
                                    type="submit"
                                >
                                    {content.loginButton}
                                </CommonButton>
                            </StyledForm>
                        </StyledCardContent>
                    </StyledCard>
                    <Divider variant="middle" />
                    <Typography textAlign="center" mt={2} variant="body1">
                        {content.copyright}
                    </Typography>
                </StyledWrapper>
            </StyledContainer>
        </>
    )
}

export default Login
