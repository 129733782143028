import { Card, CardContent, Container } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)({
    width: '100%',
    minHeight: 'calc(100vh - (63px + 101px))',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',

    '@media(max-width: 720px)': {
        padding: '2em 1em'
    }
})

export const StyledWrapper = styled('div')({
    width: '100%',
    maxWidth: 463
})

export const StyledLogo = styled('img')({
    width: '100%',
    maxWidth: 181.71,
    display: 'block',
    textAlign: 'center',
    marginBottom: '4em',
    marginLeft: 'auto',
    marginRight: 'auto',

    '@media(max-width: 720px)': {
        marginBottom: '1em'
    }
})

export const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 15,
    padding: '2em 1em',
    marginBottom: '2em',

    '@media(max-width: 720px)': {
        border: 'none'
    }
}))

export const StyledCardContent = styled(CardContent)({
    width: '100%',
    maxWidth: 318,
    margin: '0 auto'
})

export const StyledCardHeader = styled('div')({})

export const StyledTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1)
}))

export const StyledForm = styled('form')(({ theme }) => ({
    marginTop: theme.spacing(3)
}))
