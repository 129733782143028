import { styled } from '@mui/system'
import { theme } from 'components/layout/theme'

export const StyledFooter = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    height: 63,
    '@media(max-width: 720px)': {
        display: 'none'
    }
}))

export const StyledPoweredBy = styled('div')({
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    display: 'flex',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),

    '@media(max-width: 720px)': {
        textAlign: 'center',
        justifyContent: 'center'
    }
})
