import { ITableColumn } from 'utils/interfaces'

export const historyTableColumn: Array<ITableColumn> = [
    {
        columnLabel: 'Reference ID',
        columnName: 'referenceId'
    },
    {
        columnLabel: 'Date & Time',
        columnName: 'dateCreated'
    },
    {
        columnLabel: 'Reward Name',
        columnName: 'name'
    },
    {
        columnLabel: 'Reward Price',
        columnName: 'price'
    },
    {
        columnLabel: 'Total Receipients',
        columnName: 'totalReceipients'
    },
    {
        columnLabel: 'Total Amount',
        columnName: 'totalAmount'
    },
    {
        columnLabel: 'Status',
        columnName: 'status'
    },
    {
        columnLabel: 'Details',
        columnName: 'details'
    }
]
export const historyDetailTableColumn: Array<ITableColumn> = [
    {
        columnLabel: 'Recipient Number',
        columnName: 'number'
    },
    {
        columnLabel: 'Status',
        columnName: 'status'
    }
]
export const note = {
    note: 'No current rewards have been made. Send rewards now. Data are empty'
}
