import { IHistoryState } from './history.interface'
import { Action, ActionCreator } from 'redux'
import {
    HISTORY_REQUEST,
    HISTORY_REQUEST_SUCCESS,
    HISTORY_REQUEST_FAILED,
    SET_HISTORY_LIST_METADATA
} from './historyActionTypes'

export const historyRequestSuccess: ActionCreator<Action> = (
    payload: IHistoryState
) => ({
    type: HISTORY_REQUEST_SUCCESS,
    payload: {
        list: payload
    }
})

export const historyRequestFailed: ActionCreator<Action> = (
    payload: IHistoryState
) => ({
    type: HISTORY_REQUEST_FAILED,
    payload: {
        list: payload
    }
})

export const historyRequest: ActionCreator<Action> = () => ({
    type: HISTORY_REQUEST
})

export const setHistoryListMetaData: ActionCreator<Action> = (
    payload: IHistoryState
) => ({
    type: SET_HISTORY_LIST_METADATA,
    payload: {
        historyListMetaData: payload
    }
})
