import { Container } from '@mui/material'
import { styled } from '@mui/system'

export const StyledStandardBg = styled('div')({
    background: '#131313 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    width: '100%'
})
export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(5)
}))

export const StyledLogo = styled('img')({
    width: '100%',
    maxWidth: 135
})
export const StyledCopyright = styled('div')(({ theme }) => ({
    maxWidth: 400,
    widht: '100%',
    height: 130,
    textAlign: 'left',
    color: theme.palette.common.white
}))
