import { Button, Card, CardMedia, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const CardItem = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    boxShadow: '0px 0px 6px #00000029',
    borderRadius: '10px',
    height: '20rem',
    width: '16.188rem',
    marginTop: theme.spacing(0.5)
}))

export const CardImage = styled(CardMedia)({
    width: '100%',
    height: '60%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
})

export const CardDetails = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0.5)
}))
export const CardMerchant = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    height: '1.25rem',
    overflow: 'hidden'
}))
export const CardTitle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    height: '1.2rem'
})

export const MB1 = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[50],
    height: '2.2rem',
    overflow: 'hidden'
}))
export const SendButton = styled(Button)(({ theme }) => ({
    width: '100%',
    borderRadius: '23px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    ':hover': {
        background: theme.palette.primary.dark
    }
}))
