import { DialogActions, DialogTitle, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100]
}))
export const StyledContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black
}))
export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.text.primary
}))
