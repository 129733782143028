import { ReactElement } from 'react'
import { TextField } from '@mui/material'
import { MUIStyledCommonProps, styled } from '@mui/system'

interface IStyledTextField extends MUIStyledCommonProps {
    icon?: ReactElement | undefined
    disabled?: boolean
    transparent?: '1' | '0'
}

export const StyledTextField = styled(TextField)<IStyledTextField>(
    ({ theme, icon, transparent }) => ({
        '& .Mui-disabled': {
            background: theme.palette.grey[200]
        },
        '& .MuiFilledInput-input': {
            fontSize: 14,
            paddingTop: '1.367rem'
        },
        '& .MuiFilledInput-root': {
            borderRadius: icon
                ? `0 ${theme.shape.borderRadius} ${theme.shape.borderRadius} 0`
                : theme.shape.borderRadius,
            backgroundColor: Number(transparent)
                ? theme.palette.common.white
                : theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            }
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.common.black,
            fontSize: 14
        }
    })
)

interface IStyledIcon extends MUIStyledCommonProps {
    disabled?: string
}

export const StyledIcon = styled('div')<IStyledIcon>(({ theme, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Number(disabled)
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
    height: '3.125rem',
    borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
    paddingLeft: theme.spacing(1)
}))

interface IStyledTextFieldContainer extends MUIStyledCommonProps {
    mb?: number
    mt?: number
    error?: string
}

export const StyledTextFieldContainer = styled(
    'div'
)<IStyledTextFieldContainer>(({ theme, mb, mt, error }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(mb || 0),
    marginTop: theme.spacing(mt || 0),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid transparent',
    borderColor: Number(error) ? theme.palette.danger.main : 'transparent'
}))
