// Absolute
import { FC, useEffect } from 'react'
import { useAppSelector } from 'store'
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material'

// Relative
import { StyledContainer, StyledWrapper } from './history.styled'
import { DocumentTitle } from 'components/global/documentTitle'
import SuggestedList from 'components/suggestedList'
import HistoryList from 'components/historyList'
import { getRewardsSuggested } from 'store/rewards/rewardsActionThunks'
import { header } from 'pages/history/history.cont'

const History: FC = () => {
    // Hooks
    const { rewards } = useAppSelector((state) => state)
    const dispatch = useDispatch()

    // Utilities
    useEffect(() => {
        dispatch(getRewardsSuggested())
    }, [dispatch])
    return (
        <>
            <DocumentTitle title={header.history} />
            <StyledContainer>
                <StyledWrapper>
                    <Grid
                        container
                        mb={2}
                        md={12}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div>
                            <Typography variant="h3">{header.title}</Typography>
                            <Typography variant="h5" fontWeight="normal">
                                {header.subtitle}
                            </Typography>
                        </div>
                    </Grid>
                    <HistoryList />
                    <SuggestedList
                        entries={rewards.suggestedList.data}
                        loading={rewards.suggestedList.pending}
                    />
                </StyledWrapper>
            </StyledContainer>
        </>
    )
}

export default History
