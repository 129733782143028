import type { Action } from 'redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type { ILoginRequest, ILoginResponse } from './auth.interface'

import { client } from 'utils/axios.interceptor'
import { loginFailed, loginRequest, loginSuccess } from './authActionCreators'
import { GenericApiResponse } from 'utils/interfaces/genericResponse.interface'
import { setToken } from 'helpers/auth'

export const login = (
    payload: ILoginRequest
): ThunkAction<Promise<void>, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(loginRequest())

        try {
            const resp: GenericApiResponse<ILoginResponse> = await client({
                url: '/users/login',
                method: 'POST',
                data: payload
            })

            if (resp && resp.status === 200 && resp.body) {
                setToken(resp.body.token)
                dispatch(
                    loginSuccess({
                        user: {},
                        success: 'Logged in successfully.' // TODO: Replace with endpoint message
                    })
                )
            } else {
                dispatch(
                    loginFailed({
                        error: resp.message
                    })
                )
            }
        } catch (err) {
            dispatch(
                loginFailed({
                    error: (err as Error).message
                })
            )
        }
    }
}
