import { Grid, Modal, Typography } from '@mui/material'
import CommonButton from 'components/global/commonButton'
import { clearToken } from 'helpers/auth'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store'
import { closeModal } from 'store/ui/uiActionCreators'
import {
    StyledGenericModalContainer,
    StyledGenericModalContent
} from './genericModal.styled'

const GenericModal: FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ui = useAppSelector((state) => state.ui)
    const state = ui.genericModal

    const handleClose = (): void => {
        if (state.canClose && state.onClose) state.onClose()
        else {
            dispatch(closeModal())
        }
    }

    const handleCallback = (): void => {
        if (state.button?.redirect) {
            navigate(`${process.env.PUBLIC_URL}${state.button.redirect}`)
            clearToken()
            handleClose()
        } else state.button?.callback?.()
    }

    return (
        <Modal
            open={state.open}
            onClose={handleClose}
            aria-labelledby={state.title}
            aria-describedby={state.description || 'Generic Modal'}
        >
            <StyledGenericModalContainer>
                <StyledGenericModalContent>
                    <Typography id="generic-modal-titme" variant="h4">
                        {state.title}
                    </Typography>
                    {state.description && (
                        <Typography
                            id="generic-modal-description"
                            sx={{ mt: 2 }}
                        >
                            {state.description}
                        </Typography>
                    )}

                    <Grid container spacing={2} mt={3}>
                        {state.button && (
                            <Grid item>
                                <CommonButton onClick={handleCallback}>
                                    {state.button.label}
                                </CommonButton>
                            </Grid>
                        )}
                        {state.canClose && (
                            <Grid item>
                                <CommonButton
                                    onClick={handleClose}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    Close
                                </CommonButton>
                            </Grid>
                        )}
                    </Grid>
                </StyledGenericModalContent>
            </StyledGenericModalContainer>
        </Modal>
    )
}

export default GenericModal
