// Absolute
import { FC } from 'react'
import { Dialog, DialogContent, DialogContentText } from '@mui/material'

// Relative
import { IConfirmationModalProps } from './confirmationModal.interface'
import {
    StyledDialogActions,
    StyledContent,
    StyledDialogTitle
} from './confirmationModal.styled'

const ConfirmationModal: FC<IConfirmationModalProps> = (
    props: IConfirmationModalProps
) => {
    const { children, title, body, open, onClose } = props
    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <StyledContent>{body}</StyledContent>
                </DialogContentText>
            </DialogContent>
            <StyledDialogActions>{children}</StyledDialogActions>
        </Dialog>
    )
}

export default ConfirmationModal
