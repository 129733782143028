import { styled, Box } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'

export const StyledModal = styled(ModalUnstyled)({
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const modalBackground = styled('div')({
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    WebkitTapHighlightColor: 'transparent'
})

export const StyledModalBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    margin: 'auto',
    width: 752
}))

export const StyledModalBoxHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.grey[50]}`
}))

export const StyledModalBody = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    heigth: 'auto',
    width: 'auto'
}))

export const StyledModalCloseIcon = styled(CloseIcon)(() => ({
    float: 'right',
    marginTop: '20px',
    marginRight: '20px',
    fontSize: '20px',
    color: '#A2A2AF',
    '&:hover': {
        cursor: 'pointer'
    }
}))

export const StyledModalBoxFooter = styled(Box)(({ theme }) => ({
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    backgroundColor: theme.palette.grey[100],
    heigth: '60px',
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'end'
}))
export const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}))
