import { Container, Select } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)(({ theme }) => ({}))
export const StyledWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(5)
}))
export const StyledSelect = styled(Select)(({ theme }) => ({
    minWidth: 100
}))
export const StyledText = styled('div')({
    minWidth: 19
})
