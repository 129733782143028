import { isAuthenticated } from 'helpers/auth'
import { useAppSelector } from 'store'
import AuthenticatedHeader from './components/authenticatedHeader'
import NonAuthenticatedHeader from './components/nonAuthenticatedHeader'

const Header = () => {
    const auth = useAppSelector((state) => state.auth)

    if (isAuthenticated() || auth.authed) {
        return <AuthenticatedHeader />
    }

    return <NonAuthenticatedHeader />
}

export default Header
