import Layout from 'components/layout'
import Router from 'routes'
import { Typography } from '@mui/material'

import packageJson from '../package.json'

function App() {
    return (
        <Layout>
            <Router />
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    left: '20px'
                }}
            >
                <Typography>Version: {packageJson.version}</Typography>
            </div>
        </Layout>
    )
}

export default App
