import { IAuthState, TAuthActions } from './auth.interface'
import {
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT
} from './authActionTypes'

const initialState: IAuthState = {
    login: {
        user: null,
        error: '',
        success: '',
        pending: false
    },
    logout: {
        user: null,
        error: '',
        success: '',
        pending: false
    },
    authed: false
}

const authReducer = (state = initialState, action: TAuthActions) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state.login,
                login: {
                    ...state.login,
                    pending: true
                }
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    user: action.payload.login.user,
                    error: '',
                    success: action.payload.login.success,
                    pending: false
                },
                authed: true
            }
        case LOGIN_FAILED:
            return {
                ...state.login,
                login: {
                    ...state.login,
                    user: null,
                    error: action.payload.login.error,
                    success: '',
                    pending: false
                }
            }
        case LOGOUT:
            return {
                ...state.logout,
                authed: false
            }
        default:
            return state
    }
}

export default authReducer
