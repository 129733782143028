// Absolute
import type { FC } from 'react'
import { Typography } from '@mui/material'

// Relative
import type { ICommonCheckBoxProps } from './commonCheckbox.interface'
import {
    StyledCheckbox,
    StyledCheckBoxContainer,
    StyledCheckBoxIconChecked,
    StyledCheckBoxIconUnChecked
} from './commonCheckbox.styled'

const CommonCheckbox: FC<ICommonCheckBoxProps> = (
    props: ICommonCheckBoxProps
) => {
    const { checked, label, id } = props

    return (
        <StyledCheckBoxContainer>
            <StyledCheckbox
                id={id}
                disableRipple
                checked={checked}
                icon={<StyledCheckBoxIconUnChecked />}
                checkedIcon={<StyledCheckBoxIconChecked />}
            />
            <label htmlFor={id}>
                <Typography variant="body1">{label}</Typography>
            </label>
        </StyledCheckBoxContainer>
    )
}

export default CommonCheckbox
