// Absolute
import { FC } from 'react'
import usePagination from '@mui/material/usePagination'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid } from '@mui/material'

// Relative
import { ICommonPaginationProps } from './commonPagination.interface'
import {
    StyledPageButtons,
    StyledPrevNextButton
} from './commonPagination.styled'

const CommonPagination: FC<ICommonPaginationProps> = (
    props: ICommonPaginationProps
) => {
    // Hooks
    const { items } = usePagination({ ...props })

    return (
        <Grid container alignContent="center">
            {items.map(({ page, type, selected, ...item }, index) => {
                let children = null

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = '…'
                } else if (type === 'page') {
                    children = (
                        <StyledPageButtons
                            type="button"
                            style={{
                                fontWeight: selected ? 'bold' : undefined
                            }}
                            islast={Number(page) === props.count ? '1' : '0'}
                            {...item}
                        >
                            {page}
                        </StyledPageButtons>
                    )
                } else {
                    children = (
                        <StyledPrevNextButton buttonType={type} {...item}>
                            {type === 'previous' && <ChevronLeftIcon />}
                            {type === 'next' && <ChevronRightIcon />}
                        </StyledPrevNextButton>
                    )
                }

                return <span key={index}>{children}</span>
            })}
        </Grid>
    )
}

export default CommonPagination
