// Absolute
import type { FC } from 'react'
import { Grid, Typography } from '@mui/material'

// Relative
import {
    StyledBox,
    StyledCard,
    StyledContent,
    StyledMedia,
    StyledTotal,
    StyledMerchant,
    StyledDescription,
    StyledTitle,
    ColoredText
} from './rewardsCard.styled'
import { IRewardsCardProps } from './rewardsCard.interface'
import { content } from 'components/rewardsCard/rewardsCard.cont'

// Assets
import rewardLogo from 'assets/static/no-image-icon-big.svg'

export const RewardsCard: FC<IRewardsCardProps> = (
    props: IRewardsCardProps
) => {
    const { image, title, price, merchantName, description, totalRecipients } =
        props
    return (
        <StyledCard>
            <StyledBox>
                <StyledMedia image={image || rewardLogo} />
            </StyledBox>
            <StyledContent>
                <Grid container justifyContent="space-between" mb={1}>
                    <StyledTitle variant="h4">{title}</StyledTitle>
                    <ColoredText variant="h4">P {price}</ColoredText>
                </Grid>
                <StyledDescription variant="h5">
                    {description}
                </StyledDescription>
                <StyledMerchant>
                    <Typography variant="h6">{content.from}&nbsp;</Typography>
                    <ColoredText variant="h6">{merchantName}</ColoredText>
                </StyledMerchant>
                <Grid container justifyContent="space-between" mb={3}>
                    <Typography variant="h5">
                        {content.totalRecipients}
                    </Typography>
                    <Typography variant="h5" fontWeight="normal">
                        <strong>{totalRecipients}</strong>
                    </Typography>
                </Grid>
                <StyledTotal>
                    <Typography variant="h5">{content.total}</Typography>
                    <Typography variant="h6">
                        P&nbsp;{price * totalRecipients}
                    </Typography>
                </StyledTotal>
            </StyledContent>
        </StyledCard>
    )
}

export default RewardsCard
