import { MUIStyledCommonProps, styled } from '@mui/system'

interface IStyledPrevNextButton extends MUIStyledCommonProps {
    buttonType: string
}

export const StyledPrevNextButton = styled('button')<IStyledPrevNextButton>(
    ({ theme, buttonType, disabled }) => ({
        padding: '10px 15px',
        backgroundColor: theme.palette.grey[400],
        border: `1px solid ${theme.palette.grey[300]}`,
        '& svg': {
            fontSize: '0.75rem'
        },
        cursor: 'pointer',
        minHeight: 46,
        borderRadius: buttonType === 'previous' ? '5px 0 0 5px' : '0 5px 5px 0',
        color: disabled
            ? theme.palette.action.disabledBackground
            : theme.palette.primary.main
    })
)

interface IStyledPageButtons extends MUIStyledCommonProps {
    islast: '1' | '0'
}

export const StyledPageButtons = styled('button')<IStyledPageButtons>(
    ({ theme, islast }) => ({
        backgroundColor: theme.palette.common.white,
        padding: '10px 15px',
        fontSize: '0.75rem',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderLeft: Number(islast)
            ? `1px solid ${theme.palette.grey[300]}`
            : 'none',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        minHeight: 46
    })
)
