import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const StyledButton = styled(Button)(({ theme }) => ({
    paddingTop: '0.641rem',
    paddingBottom: '0.641rem',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'unset',
    boxShadow: 'none',
    color: theme.palette.primary.light,

    '.Mui-disabled': {
        backgroundColor: 'red'
    }
}))
