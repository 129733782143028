import { Card, Box, CardContent, CardMedia, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledCard = styled(Card)({
    width: '100%',
    borderRadius: '10px'
})
export const StyledBox = styled(Box)(({ theme }) => ({
    margin: theme.spacing(1)
}))
export const StyledMedia = styled(CardMedia)(({ theme }) => ({
    component: 'image',
    height: 170,
    width: '100%',
    borderRadius: '10px'
}))
export const StyledContent = styled(CardContent)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2)
}))
export const StyledTitle = styled(Typography)({
    maxWidth: '18rem'
})
export const ColoredText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}))
export const StyledDescription = styled(Typography)(({ theme }) => ({
    overflowY: 'auto',
    maxHeight: '4.5rem',
    fontWeight: 'normal',
    marginBottom: theme.spacing(1)
}))
export const StyledMerchant = styled('div')({
    display: 'flex'
})
export const StyledTotal = styled('div')(({ theme }) => ({
    background: theme.palette.grey[100],
    height: 58,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: `1px solid ${theme.palette.grey[100]}`
}))
