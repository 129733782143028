import { CircularProgress } from '@mui/material'
import { FC, memo } from 'react'
import type { ICommonButtonProps } from './commonButton.interface'
import { StyledButton } from './commonButton.styled'

const CommonButton: FC<ICommonButtonProps> = memo(
    (props: ICommonButtonProps) => {
        const {
            children,
            variant,
            color,
            fullWidth,
            disabled,
            onClick,
            type,
            startIcon,
            endIcon,
            loading
        } = props

        return (
            <StyledButton
                variant={variant || 'contained'}
                color={color || 'primary'}
                fullWidth={fullWidth}
                disabled={disabled}
                onClick={onClick}
                type={type}
                startIcon={startIcon || null}
                endIcon={endIcon || null}
            >
                {loading ? (
                    <CircularProgress color="inherit" size={20} />
                ) : (
                    children
                )}
            </StyledButton>
        )
    }
)

export default CommonButton
