import type { FC } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { StyledBG, StyledContainer, StyledLoading } from './loading.styled'

const Loading: FC = () => {
    return (
        <>
            <StyledContainer>
                <StyledBG>
                    <CircularProgress size={20} color="inherit" />
                    <StyledLoading>
                        <Typography variant="h5" fontWeight="normal">
                            Loading data
                        </Typography>
                    </StyledLoading>
                </StyledBG>
            </StyledContainer>
        </>
    )
}

export default Loading
