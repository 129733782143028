import type { FC } from 'react'
import type { ICommonTextFieldProps } from './commonTextField.interface'
import {
    StyledIcon,
    StyledTextField,
    StyledTextFieldContainer
} from './commonTextField.styled'

const CommonTextField: FC<ICommonTextFieldProps> = (
    props: ICommonTextFieldProps
) => {
    const {
        id,
        label,
        type,
        onChange,
        autoFocus,
        disabled,
        error,
        multiline,
        distanceAbove,
        distanceBelow,
        icon,
        value,
        size,
        fullWidth,
        transparent
    } = props

    return (
        <StyledTextFieldContainer
            mb={distanceBelow}
            mt={distanceAbove}
            error={error ? '1' : '0'}
        >
            {icon && (
                <StyledIcon disabled={disabled ? '1' : '0'}>{icon}</StyledIcon>
            )}
            <StyledTextField
                transparent={transparent}
                id={id}
                label={label}
                type={type || 'text'}
                variant="filled"
                icon={icon}
                value={value}
                InputProps={{
                    disableUnderline: true
                }}
                autoFocus={autoFocus}
                onChange={onChange}
                disabled={disabled}
                error={error}
                multiline={multiline}
                size={size}
                fullWidth={fullWidth}
            />
        </StyledTextFieldContainer>
    )
}

export default CommonTextField
