import { styled } from '@mui/system'

export const StyledHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    height: 63,
    '@media(max-width: 720px)': {
        display: 'none'
    }
}))
