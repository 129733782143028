import {
    recipientRequest,
    recipientRequestFailed,
    recipientRequestSuccess
} from './recipientActionCreators'
import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IRecipientRequest, IRecipientResponse } from './recipient.interface'
import { GenericApiResponse } from 'utils/interfaces/genericResponse.interface'
import { client } from 'utils/axios.interceptor'
import { removePropertyFromObjectThatHasUndefinedValue } from 'utils'

export const getRecipient = (
    payload?: IRecipientRequest
): ThunkAction<Promise<void>, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(recipientRequest())
        let _payload = payload || null

        if (_payload) {
            _payload = removePropertyFromObjectThatHasUndefinedValue(_payload)
        }
        try {
            const resp: GenericApiResponse<IRecipientResponse> = await client({
                url: '/recipients',
                method: 'GET',
                params: _payload
            })
            if (resp.status === 200 && resp.body) {
                dispatch(
                    recipientRequestSuccess({
                        data: resp.body,
                        success: 'Success'
                    })
                )
            } else {
                dispatch(
                    recipientRequestFailed({
                        error: resp.message
                    })
                )
            }
        } catch (err) {
            dispatch(
                recipientRequestFailed({
                    error: (err as Error).message
                })
            )
        }
    }
}
