import { ActionCreator, Action } from 'redux'
import { IRecipientState } from './recipient.interface'
import {
    RECIPIENT_REQUEST,
    RECIPIENT_REQUEST_FAILED,
    RECIPIENT_REQUEST_SUCCESS,
    SET_RECIPIENT_LIST_METADATA
} from './recipientActionTypes'

export const recipientRequest: ActionCreator<Action> = () => ({
    type: RECIPIENT_REQUEST
})

export const recipientRequestSuccess: ActionCreator<Action> = (
    payload: IRecipientState
) => ({
    type: RECIPIENT_REQUEST_SUCCESS,
    payload: {
        list: payload
    }
})

export const recipientRequestFailed: ActionCreator<Action> = (
    payload: IRecipientState
) => ({
    type: RECIPIENT_REQUEST_FAILED,
    payload: {
        list: payload
    }
})
export const setRecipientListMetadata: ActionCreator<Action> = (
    payload: IRecipientState
) => ({
    type: SET_RECIPIENT_LIST_METADATA,
    payload: {
        recipientListMetaData: payload
    }
})
