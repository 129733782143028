import { styled } from '@mui/system'

export const StyledBox = styled('div')(({ theme }) => ({
    overflowY: 'auto',
    width: 500,
    height: 450
}))

export const StyledNote2 = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius
}))
