// Absolute
import { FC, useEffect, useState } from 'react'
import { Grid, Link, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

// Relative
import { Card } from 'components/card/'
import Loading from 'components/layout/loading'
import { defaultSuggestedRewardsDisplayLimit } from 'utils/app.const'
import { paginate } from 'helpers/paginate'
import { TRewardsModel } from 'store/rewards/rewards.interface'
import { ISuggestedRewardsProps } from './suggestedList.interface'
import { content } from './suggestedList.cont'
import { StyledSuggestedList, StyledHeader } from './suggestedList.styled'

const SuggestedList: FC<ISuggestedRewardsProps> = (
    props: ISuggestedRewardsProps
) => {
    // Hooks
    const [entries, setEntries] = useState<Array<TRewardsModel>>([])
    const { loading } = props

    // Utilities
    const isEntriesEmpty = !props.entries || props.entries?.length <= 0

    useEffect(() => {
        const initialSet = paginate(
            props.entries ?? [],
            defaultSuggestedRewardsDisplayLimit,
            1
        )
        setEntries(initialSet)
    }, [props.entries])
    return (
        <>
            <StyledHeader>
                <Typography variant="h3">{content.content}</Typography>
                <Link
                    href={`${process.env.PUBLIC_URL}/main`}
                    underline="none"
                    color="inherit"
                >
                    <Typography variant="h5" fontWeight="normal">
                        {content.viewmore}
                    </Typography>
                </Link>
            </StyledHeader>
            <AnimatePresence exitBeforeEnter>
                <StyledSuggestedList>
                    {loading ? (
                        <motion.div
                            key="loading"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                duration: 0.1
                            }}
                        >
                            <Loading />
                        </motion.div>
                    ) : isEntriesEmpty ? (
                        <motion.div
                            key="no-reward"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Typography variant="h4" color="error">
                                {content.noRewards}
                            </Typography>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="list"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Grid container>
                                {entries &&
                                    entries.length > 0 &&
                                    entries.map((entry) => (
                                        <Grid
                                            item
                                            mb={2}
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            key={entry.id}
                                        >
                                            <Card
                                                id={entry.id}
                                                logoUrl={entry.thumbnail}
                                                name={entry.name}
                                                price={entry.amount}
                                                shortDescription={
                                                    entry.shortDescription
                                                }
                                                merchantName={
                                                    entry.merchantName
                                                }
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </motion.div>
                    )}
                </StyledSuggestedList>
            </AnimatePresence>
        </>
    )
}

export default SuggestedList
