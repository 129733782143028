const _w = window

const isAuthenticated = (): boolean => {
    // TODO: Properly need to check if user is authenticated or not
    if (getToken()) return true

    return false
}

const getToken = (): string | null => {
    const token = _w.localStorage.getItem('token')
    return token
}

const setToken = (token: string): void => {
    _w.localStorage.setItem('token', token)
}

const clearToken = (): void => {
    _w.localStorage.clear()
}

export { isAuthenticated, setToken, getToken, clearToken }
