// Absolute
import { ChangeEvent, FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

// Relative
import { IRewardsListProps } from './rewardsList.interface'
import { Card } from 'components/card'
import { StyledRewardsList } from './rewardsList.styled'
import CommonPagination from 'components/global/commonPagination'
import Loading from 'components/layout/loading'
import { content } from './rewardsList.cont'

const RewardsList: FC<IRewardsListProps> = (props: IRewardsListProps) => {
    const { onPageChange, currentPage, total, loading, entries } = props
    // Handlers
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        if (onPageChange) onPageChange(value)
    }
    // Utilities
    const isEntriesEmpty = !props.entries || props.entries?.length <= 0

    return (
        <AnimatePresence exitBeforeEnter>
            <StyledRewardsList>
                {loading ? (
                    <motion.div
                        key="loading"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        <Loading />
                    </motion.div>
                ) : isEntriesEmpty ? (
                    <motion.div
                        key="no-reward"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <Typography variant="h4" color="error">
                            {content.noRewards}
                        </Typography>
                    </motion.div>
                ) : (
                    <motion.div
                        key="list"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <Grid container>
                            {entries &&
                                entries.length > 0 &&
                                entries.map((entry) => (
                                    <Grid
                                        item
                                        mb={2}
                                        xs={12}
                                        md={4}
                                        lg={3}
                                        key={entry.id}
                                    >
                                        <Card
                                            id={entry.id}
                                            logoUrl={entry.thumbnail}
                                            name={entry.name}
                                            price={entry.amount}
                                            shortDescription={
                                                entry.shortDescription
                                            }
                                            merchantName={entry.merchantName}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </motion.div>
                )}
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <CommonPagination
                            count={total}
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </Grid>
                </Grid>
            </StyledRewardsList>
        </AnimatePresence>
    )
}

export default RewardsList
