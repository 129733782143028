import type { Action } from 'redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type {
    IRewardsRequest,
    TRewardsModel,
    IRewardsSending
} from './rewards.interface'

import { client } from 'utils/axios.interceptor'
import {
    rewardsRequest,
    rewardsRequestSuccess,
    rewardsRequestFailed,
    rewardDetailsRequestSuccess,
    rewardDetailsRequestFailed,
    suggestedRewardsRequest,
    suggestedRewardsRequestFailed,
    suggestedRewardsRequestSuccess,
    sendRewardsRequest,
    sendRewardsFailed,
    sendRewardsSuccess
} from './rewardsActionCreators'
import { GenericApiResponse } from 'utils/interfaces/genericResponse.interface'
import { removePropertyFromObjectThatHasUndefinedValue } from 'utils'

export const getReward = (
    isSingle?: boolean,
    payload?: IRewardsRequest
): ThunkAction<Promise<void>, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(rewardsRequest())
        let _payload = payload || null

        if (_payload) {
            _payload = removePropertyFromObjectThatHasUndefinedValue(_payload)
        }

        try {
            const resp: GenericApiResponse<TRewardsModel> = await client({
                url: '/rewards',
                method: 'GET',
                params: _payload
            })

            if (resp.status === 200 && resp.body) {
                dispatch(
                    isSingle
                        ? rewardDetailsRequestSuccess({
                              data: resp.body,
                              success: 'Success'
                          })
                        : rewardsRequestSuccess({
                              data: resp.body,
                              success: 'Success'
                          })
                )
            } else {
                dispatch(
                    isSingle
                        ? rewardDetailsRequestFailed({
                              error: resp.message
                          })
                        : rewardsRequestFailed({
                              error: resp.message
                          })
                )
            }
        } catch (err) {
            dispatch(
                isSingle
                    ? rewardDetailsRequestFailed({
                          error: (err as Error).message
                      })
                    : rewardsRequestFailed({
                          error: (err as Error).message
                      })
            )
        }
    }
}

export const getRewardsSuggested = (): ThunkAction<
    Promise<void>,
    {},
    {},
    Action
> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(suggestedRewardsRequest())

        try {
            const resp: GenericApiResponse<TRewardsModel> = await client({
                url: '/rewards/suggested',
                method: 'GET'
            })

            if (resp.status === 200 && resp.body) {
                dispatch(
                    suggestedRewardsRequestSuccess({
                        data: resp.body,
                        success: 'SUCCESS'
                    })
                )
            } else {
                dispatch(
                    suggestedRewardsRequestFailed({
                        error: resp.message
                    })
                )
            }
        } catch (err) {
            dispatch(
                rewardsRequestFailed({
                    error: (err as Error).message
                })
            )
        }
    }
}

export const sendRewards = (
    payload?: IRewardsSending
): ThunkAction<Promise<void>, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(sendRewardsRequest())
        try {
            const resp: GenericApiResponse<IRewardsSending> = await client({
                url: '/histories',
                method: 'POST',
                data: payload
            })

            if (resp.status === 200) {
                dispatch(
                    sendRewardsSuccess({
                        data: {},
                        success: 'Added recipients'
                    })
                )
                console.log('success')
            }
        } catch (err) {
            dispatch(
                sendRewardsFailed({
                    error: (err as Error).message
                })
            )
        }
    }
}
