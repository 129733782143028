import { styled } from '@mui/system'
import XBG from 'assets/static/X-bg.svg'

interface IStyledMain {
    pathname: string
}

export const StyledMain = styled('main')<IStyledMain>(
    ({ pathname }) =>
        pathname === `${process.env.PUBLIC_URL}/` && {
            backgroundImage: `url(${XBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'top right',

            '@media(max-width: 1020px)': {
                backgroundSize: 'cover'
            }
        }
)
