export const pathNames: string[] = ['/', '/login', '/rewards', '/history']
export const defaultRewardsDisplayLimit = 20
export const defaultSuggestedRewardsDisplayLimit = 4

export const API_ERROR_STATUS_CODE_ENUMS = {
    UNAUTHORIZED: 403
}

export const REGEX = {
    MOBILE_NUMBER_FORMAT: /^(09|\+639)\d{9}$/,
    INTERNATIONAL_DIAL_CODE: /^(\+639)\d{9}$/
}

export const STATUS_HISTORY_ENUMS = {
    DONE: 'Done',
    FAILED: 'Failed',
    ONGOING: 'Ongoing',
    PENDING: 'Pending'
}
