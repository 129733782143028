export const content = {
    details: 'Rewards Details',
    price: 'Reward Price',
    total: 'Total Amount Price',
    ongoing: 'Ongoing: ',
    done: 'Done: ',
    failed: 'Failed: ',
    pending: 'Pending: ',
    totalR: 'Total Recipients: ',
    inProcess: 'In-process Recipient: ',
    status: 'Status :',
    search: 'Search',
    recipientNumber: 'Recipient Number',
    status2: 'Status',
    back: 'Back'
}
export const status = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Done',
        value: 'Done'
    },
    {
        label: 'Ongoing',
        value: 'Ongoing'
    },
    {
        label: 'Failed',
        value: 'Failed'
    },
    {
        label: 'Pending',
        value: 'Pending'
    }
]
