import type { FC } from 'react'
import { isAuthenticated } from 'helpers/auth'
import NonAuthenticatedFooter from './components/nonAuthenticatedFooter'
import AuthenticatedFooter from './components/authenticatedFooter'
import { useAppSelector } from 'store'

const Footer: FC = () => {
    const auth = useAppSelector((state) => state.auth)

    if (isAuthenticated() || auth.authed) {
        return <AuthenticatedFooter />
    }

    return <NonAuthenticatedFooter />
}

export default Footer
