export const modalContent = {
    modalTitle: 'Import CSV File',
    modalDetails:
        'Import a CSV as a recipient, this modal allows you to upload a CSV file and import it for the easy bulk-adding recipient.',
    dropzoneDetails: 'Choose a CSV File to upload. No file chosen yet.',
    modalRecepient: 'Recepient Number',
    modalTotal: 'Total Recepient: ',
    recipientTitle: 'Recepient Number',
    recipientDetails: 'Total recipients: ',
    previewFile: 'Preview CSV File'
}
export const content = {
    addRecipient: 'Add Recipient',
    cancel: 'Cancel'
}
export const toastContent = {
    numberformatError: 'Invalid mobile number format.',
    csvError: 'Please use a CSV file to upload your data.'
}
