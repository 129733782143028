// Absolute
import { FC, useState } from 'react'
import { Typography, MenuItem, FormControl, Select } from '@mui/material'
import InputUnstyled from '@mui/material/OutlinedInput'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

// Relative
import {
    StyledMenuProps,
    StyledProps,
    StyledInputElement,
    StyledUser,
    StyledDisabledUser,
    StyledTypography
} from './dropdown.styled'
import { IDropdownProps } from './dropdown.interfaces'

export const Dropdown: FC<IDropdownProps> = ({
    items,
    placeholder,
    onSelect,
    type
}) => {
    // Hooks
    const [itemLabel, setItemLabel] = useState('')

    // Handlers
    const handleChange = (event: any) => {
        const {
            target: { value }
        } = event
        setItemLabel(typeof value === 'string' ? value.split(',') : value)
        onSelect(value)
    }
    return (
        <>
            <div>
                <FormControl>
                    <Select
                        displayEmpty
                        value={itemLabel}
                        onChange={handleChange}
                        input={
                            <InputUnstyled
                                components={{ Input: StyledInputElement }}
                            />
                        }
                        renderValue={() => {
                            return type === 'user' && placeholder !== '' ? (
                                <StyledUser variant="body2">
                                    <AccountCircleIcon />
                                    {placeholder}
                                </StyledUser>
                            ) : (
                                <Typography variant="body1">
                                    {itemLabel === ''
                                        ? items[0].value
                                        : itemLabel}
                                </Typography>
                            )
                        }}
                        MenuProps={StyledMenuProps}
                    >
                        {type === 'user' ? (
                            <MenuItem disabled value="">
                                <StyledDisabledUser variant="h5">
                                    {type === 'user' ? (
                                        <AccountCircleIcon />
                                    ) : (
                                        ''
                                    )}
                                    <StyledTypography variant="body2">
                                        {placeholder}
                                    </StyledTypography>
                                </StyledDisabledUser>
                            </MenuItem>
                        ) : null}
                        {items.map((item, index) => (
                            <MenuItem
                                key={index}
                                value={item.value}
                                style={StyledProps}
                            >
                                {item.value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    )
}
