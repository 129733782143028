import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { styled } from '@mui/system'

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    width: '100%',
    borderRadius: '10px 10px 0px 0px'
}))
export const StyledTable = styled(Table)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
    background: '#DADAE2'
}))
export const StyledRow = styled(TableRow)(({ theme }) => ({}))

export const StyledCellHeader = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12.5
}))
export const StyledCellBody = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    textAlign: 'center'
}))
export const StyledLeft = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%'
}))
export const StyledRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-end'
}))
export const StyledNotes = styled('div')(({ theme }) => ({
    height: 180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))
export const StyledOngoing = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}))

export const StyledDone = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main
}))

export const StyledFailed = styled(Typography)(({ theme }) => ({
    color: theme.palette.danger.main
}))

export const StyledPending = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main
}))
