import type { FC } from 'react'

import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import Header from './header'
import Footer from './footer'
import { theme } from './theme'

import { useLocation } from 'react-router-dom'
import { StyledMain } from './theme.styled'
import GenericModal from 'components/modal/genericModal'

// This component is only for defining the global layout of the application such as header, footer, toaster, etc.
const Layout: FC = ({ children }) => {
    const location = useLocation()
    const { pathname } = location

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <StyledMain pathname={pathname}>
                    <Header />
                    <StyledEngineProvider injectFirst>
                        {children}
                    </StyledEngineProvider>
                    <Footer />
                    <GenericModal />
                    <ToastContainer />
                </StyledMain>
            </ThemeProvider>
        </>
    )
}

export default Layout
