import History from 'pages/history'
import Login from 'pages/login'
import Main from 'pages/main'
import Playground from 'pages/playground'
import Rewards from 'pages/rewards'
import type { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

const Router: FC = () => (
    <Routes>
        {/* Implement PrivateRoute for routes that is protected by a session. */}
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
        <Route
            path={`${process.env.PUBLIC_URL}/main`}
            element={
                <PrivateRoute>
                    <Main />
                </PrivateRoute>
            }
        />
        <Route
            path={`${process.env.PUBLIC_URL}/:id/send`}
            element={
                <PrivateRoute>
                    <Rewards />
                </PrivateRoute>
            }
        />
        <Route
            path={`${process.env.PUBLIC_URL}/history`}
            element={
                <PrivateRoute>
                    <History />
                </PrivateRoute>
            }
        />
        {process.env.REACT_APP_ENV === 'DEV' && (
            <Route
                path={`${process.env.PUBLIC_URL}/playground`}
                element={<Playground />}
            />
        )}
    </Routes>
)

export default Router
