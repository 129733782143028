import {
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
    Grid
} from '@mui/material'
import { IModalHeaderProps, IPreviewModalProps } from './previewModal.interface'
import CloseIcon from '@mui/icons-material/Close'
import { modalContent } from 'pages/rewards/modal/importFileModal/importModal.cont'
import {
    StyledHeader,
    StyledDialog,
    StyledFooter,
    StyledTypography,
    StyledItem
} from 'pages/rewards/modal/importFileModal/importModal.styled'
import { StyledBox, StyledNote2 } from './previewModal.styled'

const ModalHeader = (props: IModalHeaderProps) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle {...other} sx={{ fontSize: 14 }}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

export default function PreviewFileModal(props: IPreviewModalProps) {
    const { open, handleClose, data } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-header"
        >
            <StyledHeader>
                <ModalHeader id="modal-header" onClose={handleClose}>
                    {modalContent.modalTitle}
                </ModalHeader>
            </StyledHeader>
            <StyledDialog>
                <StyledTypography>
                    <Typography variant="h5">
                        {modalContent.recipientTitle}
                    </Typography>
                    <Typography>
                        {modalContent.recipientDetails + data.length}
                    </Typography>
                </StyledTypography>
                <StyledNote2>
                    <StyledBox>
                        <Grid container>
                            {data.map((num, index) => (
                                <Grid
                                    key={index}
                                    item
                                    md={3}
                                    gridAutoRows="auto"
                                >
                                    <StyledItem>{num}</StyledItem>
                                </Grid>
                            ))}
                        </Grid>
                    </StyledBox>
                </StyledNote2>
            </StyledDialog>
            <StyledFooter />
        </Dialog>
    )
}
