import { REGEX } from './app.const'

interface IObjectReference {
    [key: string]: any
}

export const removePropertyFromObjectThatHasUndefinedValue = (
    obj: IObjectReference
): IObjectReference => {
    Object.keys(obj).forEach((key: string) => {
        if (obj[key] === undefined || obj[key] === '') {
            delete obj[key]
        }
    })

    return obj
}

export const mobileNumberValidation = (mobileNumber: string) => ({
    isMobileNumberFormat: (): boolean => {
        if (mobileNumber.match(REGEX.MOBILE_NUMBER_FORMAT)) return true
        return false
    },
    isInternationalDialCode: (): boolean => {
        if (mobileNumber.match(REGEX.INTERNATIONAL_DIAL_CODE)) return true
        return false
    }
})
