// Absolute
import type { FC } from 'react'
import { Link, Typography } from '@mui/material'

// Relative
import {
    StyledStandardBg,
    StyledContainer,
    StyledLogo,
    StyledCopyright
} from './authenticatedFooter.styled'
import { content } from './footer.cont'

// Assets
import xrewardfoot from 'assets/icons/whiteXreward-logo.svg'
import CLIENT_CONFIG from 'configs'

const AuthenticatedFooter: FC = () => {
    return (
        <StyledStandardBg>
            <StyledContainer>
                <Link href={`${process.env.PUBLIC_URL}/main`} underline="none">
                    <StyledLogo src={CLIENT_CONFIG.SECONDARY_LOGO} alt="Logo" />
                </Link>
                <StyledCopyright>
                    <Typography>{content.copyright} </Typography>
                    <br />
                    <Typography>{content.reserved} </Typography>
                    <img src={xrewardfoot} alt="XRewards Logo" />
                </StyledCopyright>
            </StyledContainer>
        </StyledStandardBg>
    )
}

export default AuthenticatedFooter
