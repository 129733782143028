// Absolute
import type { FC } from 'react'
import { ChangeEvent, useEffect, useState } from 'react'
import { useAppSelector } from 'store'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Link } from '@mui/material'

// Relative
import CommonTextField from 'components/global/commonTextField'
import CommonButton from 'components/global/commonButton'
import { Dropdown } from 'components/dropdown'
import {
    StyledContainer,
    StyledLogo,
    StyledRewards,
    StyledSearch,
    StyledStandardBg,
    StyledMyRewards,
    StyledSearchRewards,
    StyledText
} from './authenticated.styled'
import { content } from './header.cont'
import { clearToken } from 'helpers/auth'
import { logout } from 'store/auth/authActionCreators'
import { getReward } from 'store/rewards/rewardsActionThunks'
import { setListMetaData } from 'store/rewards/rewardsActionCreators'

// Assets
import CLIENT_CONFIG from 'configs'
import { MyRewardsIcon } from 'components/componentIcons/myRewardsIcon'
import { MyRewardsListIcon } from 'components/componentIcons/myRewardListIcon'

const AuthenticatedHeader: FC = () => {
    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const listMetaData = useAppSelector((state) => state.rewards.listMetaData)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const location = useLocation()

    const [selectedDropDown, setSelectedDropDown] = useState<string>('')

    // Handlers
    const handleDropdown = (value: any) => {
        if (value === content.logout) {
            clearToken()
            dispatch(logout())
            navigate(`${process.env.PUBLIC_URL}/`, { replace: true })
        }
    }

    const handleSearch = () => {
        const _listMetaData = {
            ...listMetaData,
            name: searchQuery,
            page: 1
        }

        dispatch(setListMetaData(_listMetaData))
        dispatch(getReward(false, _listMetaData))

        if (location.pathname !== `${process.env.PUBLIC_URL}/main`) {
            navigate(`${process.env.PUBLIC_URL}/main`, { replace: true })
        }
    }

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSearchQuery(value)
    }
    // Utilities

    useEffect(() => {
        handleDropdown(selectedDropDown)
        // eslint-disable-next-line
    }, [selectedDropDown])

    return (
        <StyledStandardBg>
            <StyledContainer>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <StyledLogo src={CLIENT_CONFIG.PRIMARY_LOGO} alt="Logo" />
                    <Link
                        href={`${process.env.PUBLIC_URL}/main`}
                        underline="none"
                    >
                        <StyledRewards>
                            <MyRewardsListIcon />
                            <StyledText variant="h5">
                                {content.rewardsList}
                            </StyledText>
                        </StyledRewards>
                    </Link>
                    <StyledSearchRewards>
                        <StyledSearch>
                            <CommonTextField
                                transparent="1"
                                id="Search"
                                label={content.searchreward}
                                size="small"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </StyledSearch>
                        <CommonButton onClick={() => handleSearch()}>
                            {content.searchbutton}
                        </CommonButton>
                    </StyledSearchRewards>
                    <Link
                        href={`${process.env.PUBLIC_URL}/history`}
                        underline="none"
                    >
                        <StyledMyRewards>
                            <MyRewardsIcon />
                            <StyledText variant="h5">
                                {content.myRewards}
                            </StyledText>
                        </StyledMyRewards>
                    </Link>
                    <Dropdown
                        items={[
                            {
                                id: 1,
                                label: content.logout,
                                value: content.logout
                            }
                        ]}
                        placeholder={content.profile}
                        onSelect={setSelectedDropDown}
                        type={'user'}
                    />
                </Grid>
            </StyledContainer>
        </StyledStandardBg>
    )
}
export default AuthenticatedHeader
