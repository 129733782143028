// Absolute
import { FC } from 'react'
import { useAppSelector } from 'store'
import { useDispatch } from 'react-redux'
import {
    TableBody,
    TableRow,
    Grid,
    MenuItem,
    Select,
    Typography,
    FormControl
} from '@mui/material'
import { Refresh } from '@mui/icons-material'

// Relative
import CommonButton from 'components/global/commonButton'
import CommonTextField from 'components/global/commonTextField'
import CommonPagination from 'components/global/commonPagination'
import {
    StyledTableContainer,
    StyledTableHead,
    StyledTable,
    StyledRow,
    StyledCellHeader,
    StyledCellBody,
    StyledLeft,
    StyledRight,
    StyledDone,
    StyledFailed,
    StyledOngoing,
    StyledPending
} from './historyTable.styled'
import { IHistoryTableProps } from './historyTable.interface'
import { getHistory } from 'store/history/historyActionThunks'
import {
    buttonView,
    status,
    entries,
    filter
} from 'components/historyTable/historyTable.cont'
import { STATUS_HISTORY_ENUMS } from 'utils/app.const'

export const HistoryTable: FC<IHistoryTableProps> = ({
    columns,
    rows,
    onClickViewHistory,
    onPageChange,
    totalPage,
    currentPage,
    handleOnPageSizeChange,
    currentLimit,
    currentStatus,
    onStatusFilterChange,
    totalEntries,
    historyQuery,
    onSearchChangeHistory,
    onSearchHistory
}) => {
    // Hooks
    const { historyListMetaData } = useAppSelector((state) => state.history)
    const dispatch = useDispatch()

    // Handlers
    const handleRefresh = () => {
        dispatch(getHistory(historyListMetaData))
    }

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                mb={2}
            >
                <Grid item sm={4}>
                    <StyledLeft>
                        <Typography variant="h5">{filter.show}</Typography>
                        <FormControl>
                            <Select
                                sx={{ height: 45, width: 100 }}
                                value={currentLimit}
                                onChange={handleOnPageSizeChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Sort' }}
                            >
                                {entries.map((page) => (
                                    <MenuItem key={page} value={page}>
                                        {page}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <CommonButton color="inherit" onClick={handleRefresh}>
                            <Refresh />
                        </CommonButton>
                    </StyledLeft>
                </Grid>
                <Grid item sm={8}>
                    <StyledRight>
                        <Typography variant="h5">{filter.status}</Typography>
                        <FormControl>
                            <Select
                                sx={{
                                    height: 45,
                                    width: 100
                                }}
                                value={currentStatus || ''}
                                onChange={onStatusFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Sort' }}
                            >
                                {status.map((stat) => (
                                    <MenuItem
                                        key={stat.value}
                                        value={stat.value}
                                    >
                                        {stat.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <CommonTextField
                            id={filter.search}
                            label={filter.search}
                            fullWidth
                            value={historyQuery}
                            onChange={onSearchChangeHistory}
                        />
                        <CommonButton onClick={onSearchHistory}>
                            {filter.search}
                        </CommonButton>
                    </StyledRight>
                </Grid>
            </Grid>
            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <StyledRow>
                            {columns.map((col) => {
                                return (
                                    <StyledCellHeader
                                        rowSpan={2}
                                        key={col.columnName}
                                    >
                                        {col.columnLabel}
                                    </StyledCellHeader>
                                )
                            })}
                        </StyledRow>
                        <StyledRow></StyledRow>
                    </StyledTableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.history.id}>
                                <StyledCellBody>
                                    {row.history.id}
                                </StyledCellBody>
                                <StyledCellBody>
                                    {row.history.dateCreated}
                                </StyledCellBody>
                                <StyledCellBody>
                                    {row.reward.name}
                                </StyledCellBody>
                                <StyledCellBody>
                                    P {row.reward.amount}
                                </StyledCellBody>
                                <StyledCellBody>
                                    {row.history.totalRecipients}
                                </StyledCellBody>
                                <StyledCellBody>
                                    P
                                    {row.reward.amount *
                                        row.history.totalRecipients || 0}
                                </StyledCellBody>
                                <StyledCellBody>
                                    {row.history.status ===
                                    STATUS_HISTORY_ENUMS.ONGOING ? (
                                        <StyledOngoing variant="h5">
                                            {row.history.status}
                                        </StyledOngoing>
                                    ) : row.history.status ===
                                      STATUS_HISTORY_ENUMS.FAILED ? (
                                        <StyledFailed variant="h5">
                                            {row.history.status}
                                        </StyledFailed>
                                    ) : row.history.status ===
                                      STATUS_HISTORY_ENUMS.DONE ? (
                                        <StyledDone variant="h5">
                                            {row.history.status}
                                        </StyledDone>
                                    ) : (
                                        <StyledPending variant="h5">
                                            {row.history.status}
                                        </StyledPending>
                                    )}
                                </StyledCellBody>
                                <StyledCellBody>
                                    <CommonButton
                                        onClick={() =>
                                            onClickViewHistory(row.history.id)
                                        }
                                    >
                                        {buttonView.view}
                                    </CommonButton>
                                </StyledCellBody>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography>
                            Showing 1 to {rows.length} of {totalEntries} entry
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CommonPagination
                            count={totalPage}
                            page={currentPage}
                            onChange={onPageChange}
                        />
                    </Grid>
                </Grid>
            </StyledTableContainer>
        </>
    )
}
