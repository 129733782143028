import { styled } from '@mui/system'
import { Grid, IconButton } from '@mui/material'

export const StyledDialog = styled('div')(({ theme }) => ({
    padding: theme.spacing(3)
}))

export const StyledFileDrop = styled('div')(({ theme }) => ({
    height: 380,
    display: 'flex',
    overflowY: 'auto'
}))

export const StyledAddRecipients = styled('div')({
    textAlign: 'right',
    justifyContent: 'flex-end'
})

export const StyledDropzone = styled('div')({
    textAlign: 'center',
    marginLeft: 115,
    marginTop: 150
})

export const StyledTypography = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(1)
}))

export const StyledFooter = styled('div')(({ theme }) => ({
    padding: 20,
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'space-between',
    height: 85
}))

export const StyledHeader = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[50]}`
}))

export const StyledItem = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: 5,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 10,
    maxWidth: 108,
    fontSize: 12
}))

export const StyledBackground = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px dashed ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[100],
    color: theme.palette.grey[50]
}))
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
}))
