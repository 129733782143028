// Absolute
import { FC } from 'react'
import {
    FormControl,
    MenuItem,
    Select,
    Table,
    TableCell,
    TableRow,
    Typography,
    TableHead,
    TableBody,
    TableContainer
} from '@mui/material'

// Relative
import { IHistoryDetailsProps } from './IHistoryDetailsProps.interface'
import CommonButton from 'components/global/commonButton'
import CommonTextField from 'components/global/commonTextField'
import {
    StyledCell,
    BorderedTable,
    StyledTableStatus,
    StyledCellStatus,
    StyledFilter,
    StyledRight,
    StyledSearch,
    StyledPaper,
    StyledDetails,
    StyledDone,
    StyledFailed,
    StyledOngoing,
    StyledPending
} from './historyDetailsModal.styled'
import { content, status } from './historyDetailsModal.cont'
import { STATUS_HISTORY_ENUMS } from 'utils/app.const'

export const HistoryDetails: FC<IHistoryDetailsProps> = (
    props: IHistoryDetailsProps
) => {
    const {
        columns,
        rows,
        currentStatus,
        onStatusFilterChange,
        rewardDetails,
        price,
        ongoing,
        done,
        failed,
        pending,
        totalRecipient,
        inProcess,
        totalAmount,
        query,
        onSearchChangeRecipient,
        onSearchRecipient
    } = props
    return (
        <>
            <BorderedTable>
                <TableRow>
                    <StyledCell colSpan={4}>
                        <Typography variant="h5">{content.details}</Typography>
                    </StyledCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}>
                        <StyledDetails>{rewardDetails}</StyledDetails>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <StyledCell>
                        <Typography variant="h5">{content.price}</Typography>
                    </StyledCell>
                    <TableCell>
                        <Typography>P&nbsp;{price}</Typography>
                    </TableCell>
                    <StyledCell>
                        <Typography variant="h5">{content.total}</Typography>
                    </StyledCell>
                    <TableCell>
                        <Typography>P&nbsp;{totalAmount}</Typography>
                    </TableCell>
                </TableRow>
            </BorderedTable>
            <br />
            <StyledTableStatus>
                <TableRow>
                    <StyledCellStatus>
                        <StyledOngoing variant="h5">
                            {content.ongoing} {ongoing}
                        </StyledOngoing>
                        <StyledDone variant="h5">
                            {content.done} {done}
                        </StyledDone>
                        <StyledFailed variant="h5">
                            {content.failed} {failed}
                        </StyledFailed>
                        <StyledPending variant="h5">
                            {content.pending} {pending}
                        </StyledPending>
                    </StyledCellStatus>
                </TableRow>
            </StyledTableStatus>
            <Table>
                <TableRow>
                    <TableCell>
                        <StyledFilter>
                            <div>
                                <Typography variant="h5">
                                    {content.totalR}
                                    {totalRecipient}
                                </Typography>
                                <Typography>
                                    {content.inProcess}
                                    {inProcess}
                                </Typography>
                            </div>
                            <StyledRight>
                                <StyledRight>
                                    <Typography variant="h5">
                                        {content.status}
                                    </Typography>
                                    <FormControl>
                                        <Select
                                            value={currentStatus || ''}
                                            onChange={onStatusFilterChange}
                                            displayEmpty
                                            inputProps={{
                                                'aria-label': 'Sort'
                                            }}
                                            sx={{ width: 100 }}
                                        >
                                            {status.map((stat) => (
                                                <MenuItem
                                                    key={stat.value}
                                                    value={stat.value}
                                                >
                                                    {stat.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledRight>
                                <StyledSearch>
                                    <CommonTextField
                                        id={content.search}
                                        label={content.search}
                                        value={query}
                                        onChange={onSearchChangeRecipient}
                                    />
                                </StyledSearch>
                                <CommonButton onClick={onSearchRecipient}>
                                    {content.search}
                                </CommonButton>
                            </StyledRight>
                        </StyledFilter>
                    </TableCell>
                </TableRow>
            </Table>
            <StyledPaper>
                <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => {
                                    return (
                                        <StyledCell
                                            align="center"
                                            colSpan={column.colSpan}
                                            key={column.columnName}
                                        >
                                            {column.columnLabel}
                                        </StyledCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell align="center">
                                        <Typography>{row.number}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.status ===
                                        STATUS_HISTORY_ENUMS.ONGOING ? (
                                            <StyledOngoing variant="h5">
                                                {row.status}
                                            </StyledOngoing>
                                        ) : row.status ===
                                          STATUS_HISTORY_ENUMS.FAILED ? (
                                            <StyledFailed variant="h5">
                                                {row.status}
                                            </StyledFailed>
                                        ) : row.status ===
                                          STATUS_HISTORY_ENUMS.DONE ? (
                                            <StyledDone variant="h5">
                                                {row.status}
                                            </StyledDone>
                                        ) : (
                                            <StyledPending variant="h5">
                                                {row.status}
                                            </StyledPending>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaper>
        </>
    )
}
