import { IRecipientState, TRecipientActions } from './recipient.interface'
import {
    RECIPIENT_REQUEST,
    RECIPIENT_REQUEST_FAILED,
    RECIPIENT_REQUEST_SUCCESS,
    SET_RECIPIENT_LIST_METADATA
} from './recipientActionTypes'
const initialState: IRecipientState = {
    list: {
        data: null,
        error: '',
        success: '',
        pending: false
    },
    recipientListMetaData: {}
}

export const recipientReducer = (
    state = initialState,
    action: TRecipientActions
) => {
    switch (action.type) {
        case RECIPIENT_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    pending: true
                }
            }
        case RECIPIENT_REQUEST_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.list.data,
                    error: '',
                    success: action.payload.list.success,
                    pending: false
                }
            }
        case RECIPIENT_REQUEST_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.list.data,
                    error: action.payload.list.error,
                    success: '',
                    pending: false
                }
            }
        case SET_RECIPIENT_LIST_METADATA:
            return {
                ...state,
                recipientListMetaData: {
                    ...state.recipientListMetaData,
                    historyId: action.payload.recipientListMetaData.historyId,
                    status: action.payload.recipientListMetaData.status,
                    contactNumber:
                        action.payload.recipientListMetaData.contactNumber
                }
            }
        default:
            return state
    }
}
