export const buttonView = {
    view: 'View'
}
export const filter = {
    show: 'Show Entries :',
    status: 'Status :',
    search: 'Search'
}
export const entries = ['10', '20', '100']
export const status = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Done',
        value: 'Done'
    },
    {
        label: 'Ongoing',
        value: 'Ongoing'
    },
    {
        label: 'Failed',
        value: 'Failed'
    },
    {
        label: 'Pending',
        value: 'Pending'
    }
]
