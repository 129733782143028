import { styled } from '@mui/system'
import { Checkbox } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const StyledCheckbox = styled(Checkbox)({
    padding: 0
})

export const StyledCheckBoxContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1)
}))

export const StyledCheckBoxIconUnChecked = styled(CheckCircleIcon)(
    ({ theme }) => ({
        fontSize: 16,
        color: theme.palette.grey[200]
    })
)

export const StyledCheckBoxIconChecked = styled(CheckCircleIcon)(
    ({ theme }) => ({
        fontSize: 16,
        color: theme.palette.success.main
    })
)
