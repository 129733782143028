import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledInputElement = styled('input')(({ theme }) => ({
    padding: theme.spacing(0.5, 2),
    border: `none`
}))

export const StyledMenuProps = {
    PaperProps: {
        style: {
            maxHeight: 'auto'
        }
    }
}

export const StyledProps = {
    fontWeight: 800
}

export const StyledUser = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
}))

export const StyledDisabledUser = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
})
export const StyledTypography = styled(Typography)({
    margin: '5px'
})
