// Absolute
import { FC, useCallback, useEffect, useState } from 'react'
import { Autocomplete, Chip, Grid, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store'
// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom'

// Relative
import useToast from 'helpers/useToast'
import RewardsCard from 'components/rewardsCard/index'
import CommonButton from 'components/global/commonButton'
import ConfirmationModal from 'components/global/confirmationModal/index'
import SharedNotes from 'components/global/sharedNotes'
import { DocumentTitle } from 'components/global/documentTitle'
import ImportFileModal from 'pages/rewards/modal/importFileModal'
import PreviewFileModal from 'pages/rewards/modal/previewFileModal'
import Loading from 'components/layout/loading'
import { REGEX } from 'utils/app.const'
import {
    StyledImport,
    StyledContainer,
    StyledWrapper,
    StyledLink,
    StyledTextField
} from './rewards.styled'
import { content, temporary, alert, toastAlerts, site } from './rewards.cont'
import { getReward, sendRewards } from 'store/rewards/rewardsActionThunks'

const Rewards: FC = () => {
    // Hooks
    const [openImportFileModal, setOpenImportFileModal] = useState(false)
    const [openPreviewFileModal, setOpenPreviewFileModal] = useState(false)
    const [openConfirmationAlert, setOpenConfirmationAlert] = useState(false)
    const [isEmpty, setIsEmpty] = useState(true)
    const [error, setError] = useState<boolean>(false) // Sending Rewards
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]) //  Phone numbers
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const toast = useToast()
    const rewards = useAppSelector((state) => state.rewards.rewardDetails)
    const sendReward = useAppSelector((state) => state.rewards.rewardsSending)
    const selectedReward = rewards.data?.list ? rewards.data.list[0] : null
    // End Hooks

    // Handlers
    // Import File Modal
    const handleData = useCallback((data) => {
        setPhoneNumbers(data)
        setIsEmpty(false)
    }, [])
    // End Import File Modal
    const handleCloseFileModal = () => {
        setOpenImportFileModal(false)
        setOpenPreviewFileModal(false)
    }

    const initError = () => {
        unstable_batchedUpdates(() => {
            // eslint-disable-next-line
            setError(true)
        })
    }

    const clearError = () => {
        unstable_batchedUpdates(() => {
            setError(false)
        })
    }
    // Sending Reward
    const merchId = selectedReward?.merchantId
    const denomId = selectedReward?.id
    const numbers = phoneNumbers.map((item) => {
        return { number: item.toString() }
    })
    const data = {
        reward: {
            merchantId: merchId,
            id: denomId
        },
        recipients: numbers
    }
    const handleSubmit = () => {
        if (error) clearError()
        dispatch(sendRewards(data))
    }
    // End Sending Reward

    // Confirmation Alert Modal
    const openAlert = () => {
        setOpenConfirmationAlert(true)
    }
    const closeAlert = () => {
        setOpenConfirmationAlert(false)
    }
    // End Confirmation Alert

    // End Handlers

    // Utilities
    useEffect(() => {
        if (id && id !== '') dispatch(getReward(true, { rewardId: id }))
    }, [dispatch, id])

    useEffect(() => {
        if (!sendReward.pending) {
            if (sendReward.error) {
                initError()
                toast.error(sendReward.error)
            }
            if (sendReward.success) {
                toast.success(sendReward.success)
                navigate(`${process.env.PUBLIC_URL}/history`)
            }
        }
    }, [sendReward])

    return (
        <>
            <DocumentTitle title={site.title} />
            <StyledContainer>
                <StyledWrapper>
                    <Grid container justifyContent="space-around">
                        <Grid item md={5} mb={2}>
                            {rewards.pending ? (
                                <Loading />
                            ) : (
                                <>
                                    <RewardsCard
                                        key={id}
                                        image={selectedReward?.thumbnail}
                                        title={selectedReward?.name || ''}
                                        description={
                                            selectedReward?.description || ''
                                        }
                                        totalRecipients={phoneNumbers.length}
                                        price={selectedReward?.amount || 0}
                                        merchantName={
                                            selectedReward?.merchantName || ''
                                        }
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item md={6}>
                            <Typography gutterBottom variant="h3">
                                {temporary.srdTitle}
                            </Typography>
                            <Typography variant="h5" fontWeight="normal">
                                {temporary.loremIpsum}
                            </Typography>
                            <br />
                            <Typography variant="h4" gutterBottom>
                                {content.recipient}
                            </Typography>
                            <Autocomplete
                                multiple
                                freeSolo
                                fullWidth
                                size="medium"
                                options={[]}
                                id="tags-outlined"
                                value={phoneNumbers}
                                onChange={(event: object, value: any) => {
                                    if (
                                        REGEX.MOBILE_NUMBER_FORMAT.test(
                                            value[value.length - 1]
                                        )
                                    ) {
                                        if (
                                            REGEX.INTERNATIONAL_DIAL_CODE.test(
                                                value[value.length - 1]
                                            )
                                        ) {
                                            value[value.length - 1] = value[
                                                value.length - 1
                                            ].replace('+63', '0')
                                        }
                                        setPhoneNumbers(value)
                                        setIsEmpty(false)
                                    } else if (value.length === 0) {
                                        setPhoneNumbers([])
                                        setIsEmpty(true)
                                    } else {
                                        toast.error(toastAlerts.errorNumber)
                                    }
                                }}
                                renderInput={(_params: any) => (
                                    <StyledTextField
                                        {..._params}
                                        variant="filled"
                                        color="secondary"
                                        rows={4}
                                        fullWidth
                                        multiline
                                        label="0000-0000-000"
                                        InputLabelProps={{
                                            sx: { color: 'gray' }
                                        }}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index: number) => (
                                        // eslint-disable-next-line
                                        <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                            <br />
                            <Grid container>
                                <Grid item md={6}>
                                    {!isEmpty ? (
                                        <StyledLink
                                            onClick={() =>
                                                setOpenPreviewFileModal(true)
                                            }
                                        >
                                            <Typography variant="h5">
                                                {content.view}
                                            </Typography>
                                        </StyledLink>
                                    ) : null}
                                </Grid>
                                <Grid item md={6}>
                                    <StyledImport>
                                        <CommonButton
                                            onClick={() =>
                                                setOpenImportFileModal(true)
                                            }
                                            disabled={openConfirmationAlert}
                                        >
                                            {content.csv}
                                        </CommonButton>
                                    </StyledImport>
                                </Grid>
                            </Grid>
                            <br />
                            <SharedNotes>
                                <strong>{content.noteHeader}</strong>
                                {temporary.loremIpsum}
                            </SharedNotes>
                            <br />
                            <CommonButton
                                fullWidth
                                color="success"
                                onClick={openAlert}
                                loading={sendReward.pending}
                                disabled={
                                    isEmpty ||
                                    sendReward.pending ||
                                    openConfirmationAlert
                                }
                            >
                                {content.send}
                            </CommonButton>
                        </Grid>
                    </Grid>
                </StyledWrapper>
            </StyledContainer>
            <ConfirmationModal
                title={alert.confirmation}
                body={alert.confirmQuestion}
                open={openConfirmationAlert}
                onClose={closeAlert}
            >
                <CommonButton
                    color="success"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={sendReward.pending}
                    autoFocus
                >
                    {alert.agree}
                </CommonButton>
                <CommonButton
                    color="error"
                    variant="contained"
                    onClick={closeAlert}
                >
                    {alert.cancel}
                </CommonButton>
            </ConfirmationModal>
            <ImportFileModal
                open={openImportFileModal}
                callback={handleData}
                handleClose={handleCloseFileModal}
            />
            <PreviewFileModal
                open={openPreviewFileModal}
                handleClose={handleCloseFileModal}
                data={phoneNumbers}
            />
        </>
    )
}

export default Rewards
