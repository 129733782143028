import { styled } from '@mui/system'

export const StyledSuggestedList = styled('div')({
    minHeight: 300,
    overflow: 'hidden'
})
export const StyledHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
}))
