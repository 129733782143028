import type { Action, ActionCreator } from 'redux'
import { ILoginResponse } from './auth.interface'
import {
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT
} from './authActionTypes'

export const loginRequest: ActionCreator<Action> = () => ({
    type: LOGIN_REQUEST
})

export const loginSuccess: ActionCreator<Action> = (
    payload: ILoginResponse
) => ({
    type: LOGIN_SUCCESS,
    payload: {
        login: payload
    }
})

export const logout: ActionCreator<Action> = () => ({
    type: LOGOUT
})

export const loginFailed: ActionCreator<Action> = (payload: {
    message: string
    loading: boolean
}) => ({
    type: LOGIN_FAILED,
    payload: {
        login: payload
    }
})

export const logoutRequest: ActionCreator<Action> = () => ({
    type: LOGIN_REQUEST
})

export const logoutSuccess: ActionCreator<Action> = (
    payload: ILoginResponse
) => ({
    type: LOGIN_SUCCESS,
    payload: {
        login: payload
    }
})

export const logoutFailed: ActionCreator<Action> = (payload: {
    message: string
    loading: boolean
}) => ({
    type: LOGIN_FAILED,
    payload: {
        login: payload
    }
})
