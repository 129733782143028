import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken } from 'helpers/auth'
import { API_ERROR_STATUS_CODE_ENUMS } from './app.const'

import { store } from 'store'
import { openModal } from 'store/ui/uiActionCreators'

export const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
})

client.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) config.headers!.Authorization = `Bearer ${token}`

    return config
})

client.interceptors.response.use(
    async (response: AxiosResponse) => {
        return response.data
    },
    (error: AxiosError) => {
        const _error = error.response

        if (_error?.status === API_ERROR_STATUS_CODE_ENUMS.UNAUTHORIZED) {
            store.dispatch(
                openModal({
                    open: true,
                    title: 'Invalid token',
                    description: 'Authentication is required for this action.',
                    button: {
                        label: 'Back to login',
                        redirect: '/'
                    }
                })
            )
        }

        if (_error)
            return { status: _error.status, message: _error.data.message }

        return error
    }
)
