// Absolute
import type { FC } from 'react'
import { Tooltip } from '@mui/material'
import { useTheme } from '@mui/system'

// Relative
import { ITooltipProps } from './commonTooltip.interface'

const CommonTooltip: FC<ITooltipProps> = (props: ITooltipProps) => {
    const {
        children,
        title,
        arrow,
        placement,
        open,
        onClose,
        disableHoverListener
    } = props

    // Hook
    const theme = useTheme()

    return (
        <Tooltip
            onClick={onClose}
            onClose={onClose}
            disableHoverListener={disableHoverListener}
            open={open}
            title={title}
            arrow={arrow}
            placement={placement}
            componentsProps={{
                tooltip: {
                    sx: {
                        width: 120,
                        bgcolor: theme.palette.danger.main,
                        '& .MuiTooltip-arrow': {
                            color: theme.palette.danger.main
                        }
                    }
                }
            }}
        >
            {children}
        </Tooltip>
    )
}

export default CommonTooltip
