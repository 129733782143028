// Absolute
import { FC, useEffect, useState } from 'react'
import { Tooltip, Typography, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Relative
import {
    CardItem,
    CardImage,
    CardDetails,
    CardTitle,
    MB1,
    SendButton,
    CardMerchant
} from './card.styled'
import { ICardProps } from './card.interface'

// Assets
import NoImage from 'assets/static/no-image-icon-big.svg'

export const Card: FC<ICardProps> = ({
    id,
    logoUrl,
    name,
    price,
    shortDescription,
    merchantName
}) => {
    // Hooks
    const history = useNavigate()
    const [imgSrc, setImgSrc] = useState<string>(NoImage)

    // Handlers
    const handleNavigateToRewardSend = () => {
        history(`${process.env.PUBLIC_URL}/${id}/send`)
    }

    useEffect(() => {
        if (logoUrl) {
            const srcToLoad = new Image()
            srcToLoad.src = logoUrl
            srcToLoad.onload = () => {
                setImgSrc(logoUrl)
            }
        }
    }, [logoUrl])

    return (
        <Grid container justifyContent="space-evenly">
            <CardItem>
                <CardImage image={imgSrc} />
                <CardDetails>
                    <CardTitle>
                        <Tooltip title={name} placement="top" arrow>
                            <Typography variant="h4">{name}</Typography>
                        </Tooltip>
                        <Typography variant="h4" color="primary">
                            P&nbsp;{price !== 0 ? price : 0}
                        </Typography>
                    </CardTitle>
                    <MB1>{shortDescription}</MB1>
                    <CardMerchant>
                        <Typography variant="h6">From: &nbsp;</Typography>
                        <Typography variant="h6" color="primary">
                            {merchantName}
                        </Typography>
                    </CardMerchant>
                </CardDetails>
                <SendButton onClick={handleNavigateToRewardSend}>
                    SEND
                </SendButton>
            </CardItem>
        </Grid>
    )
}
