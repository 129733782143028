import { IGenericModalState } from './ui.interface'
import { CLOSE_MODAL, OPEN_MODAL } from './uiActionTypes'

export const openModal = (payload: IGenericModalState) => ({
    type: OPEN_MODAL,
    payload
})

export const closeModal = () => ({
    type: CLOSE_MODAL
})
