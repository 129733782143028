import { isAuthenticated } from 'helpers/auth'
import type { FC, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

interface IPrivateRouteProps {
    children: ReactElement
}

const PrivateRoute: FC<IPrivateRouteProps> = ({
    children
}: IPrivateRouteProps) => {
    const _authed = isAuthenticated()

    return _authed ? children : <Navigate to={`${process.env.PUBLIC_URL}/`} />
}

export default PrivateRoute
