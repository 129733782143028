// Absolute
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'

// Relative
import type { IDocumentTitleProps } from './documentTitle.interface'
import { documentTitle } from './documentTitle.cont'

export const DocumentTitle: FC<IDocumentTitleProps> = ({
    title
}: IDocumentTitleProps) => {
    return (
        <Helmet>
            <title>
                {title} | {documentTitle.rewards}
            </title>
        </Helmet>
    )
}
