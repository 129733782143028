export const content = {
    recipient: 'Recipient Number',
    csv: 'Import CSV File',
    send: 'SEND REWARD',
    noteHeader: 'Note:',
    view: 'View all recipient number/s'
}
export const temporary = {
    loremIpsum:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est eopksio laborum.',
    srdTitle: 'Sending Rewards Details'
}
export const alert = {
    confirmation: 'Confirmation',
    confirmQuestion: 'Are you sure you want to send the rewards?',
    agree: 'Agree',
    cancel: 'Cancel'
}
export const toastAlerts = {
    errorNumber: ' Please enter valid mobile number.'
}
export const site = {
    title: 'Reward'
}
