import { styled } from '@mui/system'
import { Table, TableCell, Paper, Typography } from '@mui/material'

export const BorderedTable = styled(Table)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`
}))
export const StyledCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300]
}))

export const StyledTableStatus = styled(Table)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100]
}))
export const StyledCellStatus = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'start',
    gap: theme.spacing(5),
    borderBottom: 'none'
}))
export const StyledRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
}))
export const StyledFilter = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}))
export const StyledSearch = styled('div')(({ theme }) => ({
    width: 171
}))
export const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    overflow: 'hidden'
}))
export const StyledDetails = styled(Typography)({
    maxHeight: 150,
    overflow: 'auto'
})
export const StyledOngoing = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}))

export const StyledDone = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main
}))
export const StyledFailed = styled(Typography)(({ theme }) => ({
    color: theme.palette.danger.main
}))

export const StyledPending = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main
}))
