import {
    historyRequest,
    historyRequestSuccess,
    historyRequestFailed
} from './historyActionCreators'
import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IHistoryRequest, IHistoryResponse } from './history.interface'
import { GenericApiResponse } from 'utils/interfaces/genericResponse.interface'
import { client } from 'utils/axios.interceptor'
import { removePropertyFromObjectThatHasUndefinedValue } from 'utils'

export const getHistory = (
    payload?: IHistoryRequest
): ThunkAction<Promise<void>, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
        dispatch(historyRequest())
        let _payload = payload || null

        if (_payload) {
            _payload = removePropertyFromObjectThatHasUndefinedValue(_payload)
        }

        try {
            const resp: GenericApiResponse<IHistoryResponse> = await client({
                url: '/histories',
                method: 'GET',
                params: _payload
            })

            if (resp.status === 200 && resp.body) {
                dispatch(
                    historyRequestSuccess({
                        data: resp.body,
                        success: 'Success'
                    })
                )
            } else {
                dispatch(
                    historyRequestFailed({
                        error: resp.message
                    })
                )
            }
        } catch (err) {
            dispatch(
                historyRequestFailed({
                    error: (err as Error).message
                })
            )
        }
    }
}
