export const REWARDS_REQUEST = 'REWARDS_REQUEST'
export const REWARDS_REQUEST_SUCCESS = 'REWARDS_REQUEST_SUCCESS'
export const REWARDS_REQUEST_FAILED = 'REWARDS_REQUEST_FAILED'

export const SUGGESTED_REWARDS_REQUEST = 'SUGGESTED_REWARDS_REQUEST'
export const SUGGESTED_REWARDS_REQUEST_SUCCESS =
    'SUGGESTED_REWARDS_REQUEST_SUCCESS'
export const SUGGESTED_REWARDS_REQUEST_FAILED =
    'SUGGESTED_REWARDS_REQUEST_FAILED'

export const SET_REWARDS_LIST_METADATA = 'SET_REWARDS_LIST_METADATA'
export const RESET_LIST_METADATA = 'RESET_LIST_METADATA'

export const GET_REWARD_DETAILS_REQUEST = 'GET_REWARD_DETAILS_REQUEST'
export const GET_REWARD_DETAILS_REQUEST_SUCCESS =
    'GET_REWARD_DETAILS_REQUEST_SUCCESS'
export const GET_REWARD_DETAILS_REQUEST_FAILED =
    'GET_REWARD_DETAILS_REQUEST_FAILED'
export const SEND_REWARDS_REQUEST = 'SEND_REWARDS_REQUEST'
export const SEND_REWARDS_REQUEST_FAILED = 'SEND_REWARDS_REQUEST_FAILED'
export const SEND_REWARDS_REQUEST_SUCCESS = ' SEND_REWARDS_REQUEST_SUCCESS'
