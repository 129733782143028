import { Typography } from '@mui/material'
import { FC } from 'react'
import { StyledNote } from './notes.styled'

const SharedNotes: FC = (props) => {
    const { children } = props
    return (
        <>
            <StyledNote>
                <Typography variant="h6" fontWeight="normal">
                    {children}
                </Typography>
            </StyledNote>
        </>
    )
}

export default SharedNotes
