import {
    HISTORY_REQUEST,
    HISTORY_REQUEST_SUCCESS,
    HISTORY_REQUEST_FAILED,
    SET_HISTORY_LIST_METADATA
} from './historyActionTypes'
import { IHistoryState, THistoryActions } from './history.interface'

const initialState: IHistoryState = {
    list: {
        data: null,
        error: '',
        success: '',
        pending: false
    },
    historyListMetaData: {
        limit: 10,
        page: 1
    }
}

export const historyReducer = (
    state = initialState,
    action: THistoryActions
) => {
    switch (action.type) {
        case HISTORY_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    pending: true
                }
            }
        case HISTORY_REQUEST_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.list.data,
                    error: '',
                    success: action.payload.list.success,
                    pending: false
                }
            }
        case HISTORY_REQUEST_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.list.data,
                    error: action.payload.list.error,
                    success: '',
                    pending: false
                }
            }

        case SET_HISTORY_LIST_METADATA:
            return {
                ...state,
                historyListMetaData: {
                    ...state.historyListMetaData,
                    limit: action.payload.historyListMetaData.limit,
                    page: action.payload.historyListMetaData.page,
                    status: action.payload.historyListMetaData.status,
                    searchKeyword:
                        action.payload.historyListMetaData.searchKeyword
                }
            }

        default:
            return state
    }
}
