import primaryLogo from 'assets/icons/logoipsum-logo-32.png'
import secondaryLogo from 'assets/icons/logoipsum-logo-footer.svg'

const CLIENT_CONFIG = {
    PRIMARY_LOGO: primaryLogo,
    SECONDARY_LOGO: secondaryLogo,
    PRIMARY_COLOR: '#0000B7',
    PRIMARY_COLOR_DARK: '#080377',
    PRIMARY_COLOR_LIGHT: '#FFFFFF',
    SECONDARY_COLOR: '#1B0746'
}

export default CLIENT_CONFIG
