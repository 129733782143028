import { theme } from 'components/layout/theme'
import { ReactText } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import {
    ErrorToast,
    GenericToast,
    SuccessToast
} from 'components/global/commonToast'

export default function useToast() {
    const success = (
        msg: string,
        options?: ToastOptions
    ): ReactText | string => {
        return toast(<SuccessToast msg={msg} />, {
            ...options,
            style: {
                background: theme.palette.success.light
            },
            progressStyle: {
                background: theme.palette.success.main
            }
        })
    }

    const error = (msg: string, options?: ToastOptions): ReactText | string => {
        return toast(<ErrorToast msg={msg} />, {
            ...options,
            style: {
                background: theme.palette.error.light
            },
            progressStyle: {
                background: theme.palette.error.main
            }
        })
    }

    const generic = (
        msg: string,
        options?: ToastOptions
    ): ReactText | string => {
        return toast.success(<GenericToast msg={msg} />, {
            ...options,
            style: {
                background: theme.palette.success.light
            }
        })
    }

    return {
        success,
        error,
        generic
    }
}
