import { Typography } from '@mui/material'
import type { FC } from 'react'
import { StyledFooter, StyledPoweredBy } from './nonAuthenticatedFooter.styled'

import XRewards from 'assets/icons/Xrewards-icon.png'

const NonAuthenticatedFooter: FC = () => {
    return (
        <>
            <StyledPoweredBy>
                <Typography>Powered by</Typography>
                <img src={XRewards} alt="X Rewards" />
            </StyledPoweredBy>
            <StyledFooter />
        </>
    )
}

export default NonAuthenticatedFooter
