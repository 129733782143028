import { Container, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledDashboardContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(5),
    minHeight: '100vh'
}))
export const StyledDropdown = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 187
})
export const StyledTitle = styled('div')(({ theme }) => ({
    maxWidth: 332,
    width: '100%'
}))
export const StyledTextField = styled('div')(({ theme }) => ({
    maxWidth: 110,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[50]}`
}))
export const StyledFilterOption = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
}))
export const StyledSearchedResult = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[50]
}))
