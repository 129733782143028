import { ActionCreator, Action } from 'redux'
import { IRewardsState } from './rewards.interface'
import {
    GET_REWARD_DETAILS_REQUEST_FAILED,
    GET_REWARD_DETAILS_REQUEST_SUCCESS,
    GET_REWARD_DETAILS_REQUEST,
    REWARDS_REQUEST,
    REWARDS_REQUEST_FAILED,
    REWARDS_REQUEST_SUCCESS,
    SUGGESTED_REWARDS_REQUEST,
    SUGGESTED_REWARDS_REQUEST_FAILED,
    SUGGESTED_REWARDS_REQUEST_SUCCESS,
    SET_REWARDS_LIST_METADATA,
    SEND_REWARDS_REQUEST,
    SEND_REWARDS_REQUEST_SUCCESS,
    SEND_REWARDS_REQUEST_FAILED,
    RESET_LIST_METADATA
} from './rewardsActionTypes'

export const rewardsRequest: ActionCreator<Action> = () => ({
    type: REWARDS_REQUEST
})
export const rewardsRequestSuccess: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: REWARDS_REQUEST_SUCCESS,
    payload: {
        list: payload
    }
})

export const rewardsRequestFailed: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: REWARDS_REQUEST_FAILED,
    payload: {
        list: payload
    }
})

export const suggestedRewardsRequest: ActionCreator<Action> = () => ({
    type: SUGGESTED_REWARDS_REQUEST
})

export const suggestedRewardsRequestSuccess: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: SUGGESTED_REWARDS_REQUEST_SUCCESS,
    payload: {
        suggestedList: payload
    }
})

export const suggestedRewardsRequestFailed: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: SUGGESTED_REWARDS_REQUEST_FAILED,
    payload: {
        suggestedList: payload
    }
})
export const rewardDetailsRequestSuccess: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: GET_REWARD_DETAILS_REQUEST_SUCCESS,
    payload: {
        rewardDetails: payload
    }
})

export const rewardDetailsRequestFailed: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: GET_REWARD_DETAILS_REQUEST_FAILED,
    payload: {
        rewardDetails: payload
    }
})

export const rewardDetailsRequest: ActionCreator<Action> = () => ({
    type: GET_REWARD_DETAILS_REQUEST
})

export const setListMetaData: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: SET_REWARDS_LIST_METADATA,
    payload: {
        listMetaData: payload
    }
})

export const resetListMetaData: ActionCreator<Action> = () => ({
    type: RESET_LIST_METADATA
})

export const sendRewardsRequest: ActionCreator<Action> = () => ({
    type: SEND_REWARDS_REQUEST
})

export const sendRewardsSuccess: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: SEND_REWARDS_REQUEST_SUCCESS,
    payload: {
        rewardsSending: payload
    }
})

export const sendRewardsFailed: ActionCreator<Action> = (
    payload: IRewardsState
) => ({
    type: SEND_REWARDS_REQUEST_FAILED,
    payload: {
        rewardsSending: payload
    }
})
