import { combineReducers } from 'redux'

import authReducer from './auth/auth.reducer'
import { historyReducer } from './history/history.reducer'
import { recipientReducer } from './recipient/recipient.reducer'
import { rewardsReducer } from './rewards/rewards.reducer'
import uiReducer from './ui/ui.reducer'

const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    rewards: rewardsReducer,
    history: historyReducer,
    recipient: recipientReducer
})

export default rootReducer
