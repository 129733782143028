import { Container } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const StyledBG = styled('div')(({ theme }) => ({
    background: theme.palette.grey[100],
    borderRadius: '10px',
    maxWidth: 1085,
    width: '100%',
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1)
}))

export const StyledLoading = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.grey[50]
}))
