import useToast from 'helpers/useToast'
import { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store'
// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom'
import type { IUseLoginHook, IUserInput } from './login.interface'
import { useNavigate } from 'react-router-dom'
import { login } from 'store/auth/authActionThunks'

export default function useLoginForm(): IUseLoginHook {
    const dispatch = useDispatch()
    const auth = useAppSelector((state) => state.auth)
    const navigate = useNavigate()
    const [userInput, setUserInput] = useState<IUserInput>({
        username: '',
        password: ''
    })
    const [error, setError] = useState<boolean>(false)
    const [isTooltipErrorOpen, setTooltipErrorOpen] = useState<boolean>(false)
    const toast = useToast()

    const initError = () => {
        // eslint-disable-next-line
        unstable_batchedUpdates(() => {
            setError(true)
            setTooltipErrorOpen(true)
        })
    }

    const clearError = () => {
        unstable_batchedUpdates(() => {
            setError(false)
            setTooltipErrorOpen(false)
        })
    }

    const onFieldChange = (event: FormEvent<HTMLInputElement>) => {
        // Note (TJ): Clear error and tooltip
        if (error && isTooltipErrorOpen) clearError()

        const value = event.currentTarget.value
        const key = event.currentTarget.getAttribute('id')

        if (key) {
            setUserInput((prevState: IUserInput) => ({
                ...prevState,
                [key]: value
            }))
        } else {
            throw new Error('ID is required')
        }
    }

    const onLogin = () => {
        if (error && isTooltipErrorOpen) clearError() // Clear errors if re-submit
        const payload = userInput

        dispatch(login(payload))
    }

    useEffect(() => {
        if (!auth.login?.pending) {
            if (auth.login?.error) {
                initError()
                toast.error(auth.login.error)
            }

            if (auth.login?.success) {
                toast.success(auth.login.success)
                navigate(`${process.env.PUBLIC_URL}/main`, { replace: true })
            }
        }
    }, [auth]) // eslint-disable-line

    return {
        onFieldChange,
        userInput,
        onLogin,
        error,
        isTooltipErrorOpen,
        setTooltipErrorOpen,
        loading: auth.login?.pending || false
    }
}
