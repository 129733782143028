// Absolute
import type { FC } from 'react'
import { Typography } from '@mui/material'

// Relative
import { IModalProps } from './modal.interface'
import CommonButton from 'components/global/commonButton'
import {
    StyledModal,
    modalBackground,
    StyledModalBox,
    StyledModalBoxHeader,
    StyledModalBoxFooter,
    StyledModalCloseIcon,
    StyledModalBody,
    StyledTitle
} from './modal.styled'

export const Modal: FC<IModalProps> = (props: IModalProps) => {
    const { modalData, open, close, children } = props
    return (
        <>
            <StyledModal
                open={open}
                onClose={() => close(false)}
                BackdropComponent={modalBackground}
            >
                <Typography variant="body1">
                    <StyledModalBox>
                        <StyledModalCloseIcon onClick={() => close(false)} />
                        <StyledModalBoxHeader>
                            <StyledTitle variant="h5">
                                {modalData.modalTitle}
                            </StyledTitle>
                        </StyledModalBoxHeader>
                        <StyledModalBody>{children}</StyledModalBody>
                        <StyledModalBoxFooter>
                            <CommonButton onClick={() => close(false)}>
                                Back
                            </CommonButton>
                        </StyledModalBoxFooter>
                    </StyledModalBox>
                </Typography>
            </StyledModal>
        </>
    )
}
