import { IUIState, TUIActions } from './ui.interface'
import { CLOSE_MODAL, OPEN_MODAL } from './uiActionTypes'

const initialState: IUIState = {
    genericModal: {
        open: false,
        onClose: () => {},
        title: '',
        description: ''
    }
}

const uiReducer = (state = initialState, action: TUIActions) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                genericModal: {
                    ...state.genericModal,
                    open: true,
                    onClose: action.payload.onClose,
                    title: action.payload.title,
                    description: action.payload.description,
                    button: action.payload.button,
                    canClose: action.payload.canClose
                }
            }
        case CLOSE_MODAL:
            return {
                ...state,
                genericModal: {
                    ...state.genericModal,
                    open: false
                }
            }
        default:
            return state
    }
}

export default uiReducer
