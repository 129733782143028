import { IRewardsState, TRewardsActions } from './rewards.interface'
import {
    GET_REWARD_DETAILS_REQUEST,
    GET_REWARD_DETAILS_REQUEST_FAILED,
    GET_REWARD_DETAILS_REQUEST_SUCCESS,
    REWARDS_REQUEST,
    REWARDS_REQUEST_FAILED,
    REWARDS_REQUEST_SUCCESS,
    SET_REWARDS_LIST_METADATA,
    SUGGESTED_REWARDS_REQUEST,
    SUGGESTED_REWARDS_REQUEST_FAILED,
    SUGGESTED_REWARDS_REQUEST_SUCCESS,
    SEND_REWARDS_REQUEST,
    SEND_REWARDS_REQUEST_SUCCESS,
    SEND_REWARDS_REQUEST_FAILED,
    RESET_LIST_METADATA
} from './rewardsActionTypes'

const initialState: IRewardsState = {
    list: {
        data: null,
        error: '',
        success: '',
        pending: false
    },
    suggestedList: {
        data: null,
        error: '',
        success: '',
        pending: false
    },
    listMetaData: {
        limit: 12,
        page: 1
    },
    rewardDetails: {
        data: null,
        error: '',
        success: '',
        pending: false
    },
    rewardsSending: {
        data: null,
        error: '',
        success: '',
        pending: false
    }
}

export const rewardsReducer = (
    state = initialState,
    action: TRewardsActions
) => {
    switch (action.type) {
        case REWARDS_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    pending: true
                }
            }
        case REWARDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.list.data,
                    error: '',
                    success: action.payload.list.success,
                    pending: false
                }
            }
        case REWARDS_REQUEST_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: action.payload.list.error,
                    success: '',
                    pending: false
                }
            }
        case SUGGESTED_REWARDS_REQUEST:
            return {
                ...state,
                suggestedList: {
                    ...state.suggestedList,
                    pending: true
                }
            }
        case SUGGESTED_REWARDS_REQUEST_SUCCESS:
            return {
                ...state,
                suggestedList: {
                    ...state.suggestedList,
                    data: action.payload.suggestedList.data,
                    error: '',
                    success: action.payload.suggestedList.success,
                    pending: false
                }
            }
        case SUGGESTED_REWARDS_REQUEST_FAILED:
            return {
                ...state,
                suggestedList: {
                    ...state.suggestedList,
                    error: action.payload.suggestedList.error,
                    success: '',
                    pending: false
                }
            }
        case GET_REWARD_DETAILS_REQUEST:
            return {
                ...state,
                rewardDetails: {
                    ...state.rewardDetails,
                    pending: true
                }
            }
        case GET_REWARD_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                rewardDetails: {
                    ...state.rewardDetails,
                    data: {
                        list: action.payload.rewardDetails.data.list
                    },
                    error: '',
                    success: action.payload.rewardDetails.success,
                    pending: false
                }
            }
        case GET_REWARD_DETAILS_REQUEST_FAILED:
            return {
                ...state,
                rewardDetails: {
                    ...state.rewardDetails,
                    error: action.payload.rewardDetails.error,
                    success: '',
                    pending: false
                }
            }
        case SET_REWARDS_LIST_METADATA:
            return {
                ...state,
                listMetaData: {
                    ...state.listMetaData,
                    limit: action.payload.listMetaData.limit,
                    page: action.payload.listMetaData.page,
                    name: action.payload.listMetaData.name,
                    minPrice: action.payload.listMetaData.minPrice,
                    maxPrice: action.payload.listMetaData.maxPrice,
                    sortByColumn: action.payload.listMetaData.sortByColumn,
                    asc: action.payload.listMetaData.asc
                }
            }
        case RESET_LIST_METADATA:
            return {
                ...state,
                listMetaData: {
                    limit: 12,
                    page: 1
                }
            }
        case SEND_REWARDS_REQUEST:
            return {
                ...state,
                rewardsSending: {
                    ...state.rewardsSending,
                    pending: true
                }
            }
        case SEND_REWARDS_REQUEST_FAILED:
            return {
                ...state,
                rewardsSending: {
                    ...state.rewardsSending,
                    error: action.payload.rewardsSending.error,
                    success: '',
                    pending: false
                }
            }
        case SEND_REWARDS_REQUEST_SUCCESS:
            return {
                ...state,
                rewardsSending: {
                    ...state.rewardsSending,
                    data: action.payload.rewardsSending.data,
                    error: '',
                    success: action.payload.rewardsSending.success,
                    pending: false
                }
            }
        default:
            return state
    }
}
