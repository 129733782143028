export const totalRewards = (total: number): string => {
    return `Over ${total.toLocaleString()} items here, Give rewards now.`
}
export const totalSearchedRewards = (
    total: number,
    searched: string
): string => {
    return `${total.toLocaleString()} items found for "${searched}"`
}

export const content = {
    title: 'Rewards List',
    sortBy: 'Sort by:',
    price: 'Price:',
    search: 'Search Result'
}
export const sortBy = [
    {
        label: 'None',
        value: '',
        selected: true
    },
    {
        label: 'Name A-Z',
        value: 'name_asc'
    },
    {
        label: 'Name Z-A',
        value: 'name'
    },
    {
        label: 'Price Low to High',
        value: 'amount_asc'
    },
    {
        label: 'Price High to Low',
        value: 'amount'
    }
]
