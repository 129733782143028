import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { Action, applyMiddleware, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import rootReducer from './reducer'
import IRootState from './store.interface'

export const store = createStore(
    rootReducer,
    applyMiddleware(thunk as ThunkMiddleware<any, Action<any>>)
)
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
