import { Typography } from '@mui/material'
import { StyledContainer } from './playground.styled'

const Playground = () => {
    // NOTE: This is for testing segregated components only.
    return (
        <StyledContainer>
            <Typography>
                Use this to test segregated components only.
            </Typography>
        </StyledContainer>
    )
}

export default Playground
