// Absolute
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store'
import {
    FormControl,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

// Relative
import CommonButton from 'components/global/commonButton'
import CommonTextField from 'components/global/commonTextField'
import { DocumentTitle } from 'components/global/documentTitle'
import RewardsList from 'components/rewardsList'

import { getReward } from 'store/rewards/rewardsActionThunks'
import {
    resetListMetaData,
    setListMetaData
} from 'store/rewards/rewardsActionCreators'
import {
    StyledDashboardContainer,
    StyledDropdown,
    StyledTitle,
    StyledTextField,
    StyledFilterOption,
    StyledSearchedResult
} from './main.styled'
import {
    content,
    sortBy,
    totalRewards,
    totalSearchedRewards
} from './main.cont'

const Main: FC = () => {
    // Hooks
    const [minMaxFilter, setMinMaxFilter] = useState<{
        minPrice: string
        maxPrice: string
    }>({
        minPrice: '',
        maxPrice: ''
    })

    const dispatch = useDispatch()
    const rewards = useAppSelector((state) => state.rewards)
    const { listMetaData } = rewards
    const totalPage = rewards.list.data?.totalPageCount || 0

    // Handlers
    const handleFilter = () => {
        const _minPrice = Number(minMaxFilter.minPrice) || undefined
        const _maxPrice = Number(minMaxFilter.maxPrice) || undefined

        const _listMetaData = {
            ...listMetaData,
            minPrice: _minPrice,
            maxPrice: _maxPrice,
            page: 1
        }

        dispatch(setListMetaData(_listMetaData))
        dispatch(getReward(false, _listMetaData))
    }

    const handleOnSortChange = (event: SelectChangeEvent) => {
        let column = event.target.value as string
        const newListMetaData = JSON.parse(JSON.stringify(listMetaData))

        if (column === '') {
            delete newListMetaData.sortByColumn
            delete newListMetaData.asc
        } else {
            let asc = false

            if (column.includes('_asc')) {
                asc = true
                column = column.split('_')[0]
            }

            newListMetaData.sortByColumn = column
            newListMetaData.asc = asc
        }

        dispatch(setListMetaData(newListMetaData))
        dispatch(getReward(false, newListMetaData))
    }

    const handlePageChange = (value: number) => {
        if (listMetaData) {
            dispatch(setListMetaData({ ...listMetaData, page: value }))

            dispatch(
                getReward(false, {
                    ...listMetaData,
                    page: value
                })
            )
        }
    }

    const handleMinMaxFilterChange = (
        event: ChangeEvent<HTMLInputElement>,
        field: 'minPrice' | 'maxPrice'
    ) => {
        const value = event.target.value as string

        setMinMaxFilter((prevMinMax) => {
            return {
                ...prevMinMax,
                [field]: value
            }
        })
    }

    // Utilities
    const cleanUp = () => {
        dispatch(resetListMetaData())
    }

    useEffect(() => {
        dispatch(getReward(false, listMetaData))

        return () => cleanUp() // CLEANUP: Reset rewards metadata when this component is unmount
    }, [dispatch])

    const searchedRewards =
        listMetaData.name === '' || listMetaData.name === undefined
    return (
        <>
            <DocumentTitle title="Dashboard" />
            <StyledDashboardContainer>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                    ml={0}
                    mr={0}
                >
                    <Grid item>
                        {searchedRewards ? (
                            <StyledTitle>
                                <Typography variant="h3">
                                    {content.title}
                                </Typography>
                                <Typography variant="h5" fontWeight="normal">
                                    {totalRewards(
                                        rewards.list.data?.totalListSize || 0
                                    )}
                                </Typography>
                            </StyledTitle>
                        ) : (
                            <StyledTitle>
                                <Typography variant="h3" gutterBottom>
                                    {content.search}
                                </Typography>
                                <Typography variant="h5">
                                    {listMetaData.name}
                                </Typography>
                                <StyledSearchedResult
                                    variant="h5"
                                    fontWeight="normal"
                                >
                                    {totalSearchedRewards(
                                        rewards.list.data?.totalListSize || 0,
                                        listMetaData.name || ''
                                    )}
                                </StyledSearchedResult>
                            </StyledTitle>
                        )}
                    </Grid>
                    <Grid item>
                        <StyledFilterOption>
                            <Typography variant="h4">
                                {content.sortBy}
                            </Typography>
                            <StyledDropdown>
                                <FormControl fullWidth>
                                    <Select
                                        value={listMetaData.sortByColumn}
                                        onChange={handleOnSortChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Sort' }}
                                        defaultValue=""
                                    >
                                        {sortBy.map((column, index) => (
                                            <MenuItem
                                                key={index}
                                                value={column.value}
                                                selected={column.selected}
                                            >
                                                {column.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </StyledDropdown>
                            <Typography variant="h4">
                                {content.price}
                            </Typography>
                            <StyledTextField>
                                <CommonTextField
                                    id="min"
                                    label="Min"
                                    size="small"
                                    value={minMaxFilter.minPrice}
                                    transparent="1"
                                    onChange={(event) =>
                                        handleMinMaxFilterChange(
                                            event,
                                            'minPrice'
                                        )
                                    }
                                />
                            </StyledTextField>
                            <Typography>-</Typography>
                            <StyledTextField>
                                <CommonTextField
                                    id="max"
                                    label="Max"
                                    size="small"
                                    value={minMaxFilter.maxPrice}
                                    transparent="1"
                                    onChange={(event) =>
                                        handleMinMaxFilterChange(
                                            event,
                                            'maxPrice'
                                        )
                                    }
                                />
                            </StyledTextField>
                            <CommonButton size="medium" onClick={handleFilter}>
                                <PlayArrowIcon />
                            </CommonButton>
                        </StyledFilterOption>
                    </Grid>
                </Grid>
                <>
                    <RewardsList
                        entries={rewards.list.data?.list}
                        total={totalPage}
                        onPageChange={handlePageChange}
                        currentPage={listMetaData.page}
                        loading={rewards.list.pending}
                    />
                </>
            </StyledDashboardContainer>
        </>
    )
}

export default Main
