import { Container, Link, TextField } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10.4)
}))
export const StyledWrapper = styled('div')({
    width: '100%'
})
export const StyledImport = styled('div')({
    textAlign: 'right'
})

export const StyledLink = styled(Link)({
    cursor: 'pointer'
})
export const StyledTextField = styled(TextField)({
    maxHeight: 150,
    overflowY: 'auto'
})
