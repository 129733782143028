// Absolute
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress, SelectChangeEvent, Grid } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppSelector } from 'store'

// Relative
import SharedNotes from 'components/global/sharedNotes'
import { HistoryTable } from 'components/historyTable'
import { StyledNotes } from 'components/historyTable/historyTable.styled'
import Loading from 'components/layout/loading'
import { setHistoryListMetaData } from 'store/history/historyActionCreators'
import {
    historyTableColumn,
    historyDetailTableColumn,
    note
} from './historyList.const'
import { getHistory } from 'store/history/historyActionThunks'
import { Modal } from 'components/modal'
import { HistoryDetails } from 'components/historyTable/historyDetailsModal'
import { setRecipientListMetadata } from 'store/recipient/recipientActionCreators'
import { getRecipient } from 'store/recipient/recipientActionThunks'
import { StyledHistoryList } from './historyList.styled'

const HistoryList: FC = () => {
    // Hooks
    const dispatch = useDispatch()
    const { list, historyListMetaData } = useAppSelector(
        (state) => state.history
    )
    const recipients = useAppSelector((state) => state.recipient)
    const recipientListMetaData = recipients.recipientListMetaData
    const [recipientQuery, setRecipientQuery] = useState<string>('')
    const [historyQuery, setHistoryQuery] = useState<string>('')
    const [historyDetailsModalVisibility, setHistoryDetailsModalVisibility] =
        useState<boolean>(false)

    // Handlers

    const handleSearchRecipient = () => {
        const _recipientListMetaData = {
            ...recipientListMetaData,
            contactNumber: recipientQuery
        }
        dispatch(setRecipientListMetadata(_recipientListMetaData))
        dispatch(getRecipient(_recipientListMetaData))
    }

    const handleRecipientSearchChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value
        setRecipientQuery(value)
    }

    const handleSearchHistory = () => {
        const _historyListMetaData = {
            ...historyListMetaData,
            searchKeyword: historyQuery
        }
        dispatch(setHistoryListMetaData(_historyListMetaData))
        dispatch(getHistory(_historyListMetaData))
    }

    const handleHistorySearchChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const historyValue = event.target.value
        setHistoryQuery(historyValue)
    }

    const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
        if (historyListMetaData) {
            dispatch(setHistoryListMetaData({ ...historyListMetaData, page }))

            dispatch(
                getHistory({
                    ...historyListMetaData,
                    page
                })
            )
        }
    }

    const handleOnPageSizeChange = (event: SelectChangeEvent<number>) => {
        const pageSize = Number(event.target.value)

        if (historyListMetaData) {
            dispatch(
                setHistoryListMetaData({
                    ...historyListMetaData,
                    limit: pageSize
                })
            )

            dispatch(
                getHistory({
                    ...historyListMetaData,
                    limit: pageSize
                })
            )
        }
    }

    const handleOnStatusFilterChange = (event: SelectChangeEvent<string>) => {
        const status = String(event.target.value)

        if (historyListMetaData) {
            dispatch(
                setHistoryListMetaData({
                    ...historyListMetaData,
                    status: status
                })
            )

            dispatch(
                getHistory({
                    ...historyListMetaData,
                    status: status
                })
            )
        }
    }

    const handleOnStatusRecipientFilterChange = (
        event: SelectChangeEvent<string>
    ) => {
        const status = String(event.target.value)

        if (recipientListMetaData) {
            dispatch(
                setRecipientListMetadata({
                    ...recipientListMetaData,
                    status: status
                })
            )

            dispatch(
                getRecipient({
                    ...recipientListMetaData,
                    status: status
                })
            )
        }
    }

    // Utilities
    const onClickViewHistory = (id: number) => {
        setHistoryDetailsModalVisibility(true)
        dispatch(setRecipientListMetadata({ historyId: id }))
        dispatch(getRecipient({ historyId: id }))
    }

    const getTotalOfSpecificStatus = (status: string): number => {
        const _recipients = recipients.list.data?.recipients
        let filteredRecipientsByStatusString = []

        if (_recipients) {
            filteredRecipientsByStatusString = _recipients.filter(
                (_recipient) => _recipient.status === status
            )
        }

        return filteredRecipientsByStatusString.length
    }

    const getTotalAmount = () => {
        if (recipients.list.data?.rewards && recipients.list.data?.recipients) {
            return (
                recipients.list.data?.rewards.price *
                recipients.list.data?.recipients.length
            )
        }
        return 0
    }

    const isEntriesEmpty = list.data?.totalListSize === 0

    useEffect(() => {
        dispatch(getHistory(historyListMetaData))
    }, [dispatch])
    return (
        <>
            <AnimatePresence exitBeforeEnter>
                <StyledHistoryList>
                    {list.pending ? (
                        <motion.div
                            key="loading"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                duration: 0.1
                            }}
                        >
                            <Loading />
                        </motion.div>
                    ) : isEntriesEmpty ? (
                        <motion.div
                            key="no-history"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <SharedNotes>
                                <StyledNotes>{note.note}</StyledNotes>
                            </SharedNotes>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="list"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <HistoryTable
                                rows={list.data?.list || []}
                                columns={historyTableColumn}
                                onClickViewHistory={onClickViewHistory}
                                onPageChange={handlePageChange}
                                currentPage={historyListMetaData.page || 1}
                                totalPage={list.data?.totalPageCount || 0}
                                handleOnPageSizeChange={handleOnPageSizeChange}
                                currentLimit={historyListMetaData.limit || 10}
                                onStatusFilterChange={
                                    handleOnStatusFilterChange
                                }
                                currentStatus={historyListMetaData.status}
                                totalEntries={list.data?.totalListSize || 0}
                                historyQuery={historyQuery}
                                onSearchChangeHistory={
                                    handleHistorySearchChange
                                }
                                onSearchHistory={handleSearchHistory}
                            />
                        </motion.div>
                    )}
                </StyledHistoryList>
            </AnimatePresence>

            <Modal
                modalData={{
                    modalTitle: `Reward Name: ${recipients.list.data?.rewards.name}`
                }}
                open={historyDetailsModalVisibility}
                close={setHistoryDetailsModalVisibility}
            >
                {recipients.list.pending ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <HistoryDetails
                        rewardDetails={
                            recipients.list.data?.rewards.description || ''
                        }
                        price={recipients.list.data?.rewards.price || 0}
                        totalAmount={getTotalAmount()}
                        ongoing={getTotalOfSpecificStatus('Ongoing')}
                        done={getTotalOfSpecificStatus('Done')}
                        failed={getTotalOfSpecificStatus('Failed')}
                        pending={getTotalOfSpecificStatus('Pending')}
                        totalRecipient={
                            recipients.list.data?.recipients?.length || 0
                        }
                        inProcess={getTotalOfSpecificStatus('Ongoing')}
                        currentStatus={recipientListMetaData.status}
                        columns={historyDetailTableColumn}
                        onStatusFilterChange={
                            handleOnStatusRecipientFilterChange
                        }
                        rows={recipients?.list?.data?.recipients || []}
                        query={recipientQuery}
                        onSearchChangeRecipient={handleRecipientSearchChange}
                        onSearchRecipient={handleSearchRecipient}
                    />
                )}
            </Modal>
        </>
    )
}

export default HistoryList
