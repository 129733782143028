// Absolute
import { MutableRefObject, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { MenuBookOutlined } from '@mui/icons-material'
import { Grid, Dialog, DialogTitle, Typography } from '@mui/material'
import Papa from 'papaparse'
import { FileDrop } from 'react-file-drop'
// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom'

// Relative
import useToast from 'helpers/useToast'
import { mobileNumberValidation } from 'utils'
import CommonButton from 'components/global/commonButton'
import {
    StyledDialog,
    StyledAddRecipients,
    StyledDropzone,
    StyledFooter,
    StyledFileDrop,
    StyledHeader,
    StyledTypography,
    StyledItem,
    StyledBackground,
    StyledIconButton
} from './importModal.styled'
import { IModalHeaderProps, IImportModalProps } from './importModal.interface'
import { content, modalContent, toastContent } from './importModal.cont'

const ModalHeader = (props: IModalHeaderProps) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle {...other} sx={{ fontSize: 14 }}>
            {children}
            {onClose ? (
                <StyledIconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </StyledIconButton>
            ) : null}
        </DialogTitle>
    )
}

export default function ImportFileModal(props: IImportModalProps) {
    // Hooks
    const { open, handleClose, callback } = props
    const [phoneNo, setPhoneNo] = useState<Array<string>>([])
    const [file, setFile] = useState(false)
    const [error, setError] = useState(false)
    const toast = useToast()
    const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>

    // Handlers
    const initError = () => {
        unstable_batchedUpdates(() => {
            setError(true)
        })
    }
    const clearError = () => {
        unstable_batchedUpdates(() => {
            setError(false)
        })
    }
    const onTargetClick = () => {
        fileInputRef.current.click()
    }
    const handleSubmit = () => {
        callback(phoneNo)
        handleClose()
    }
    const handleFile = (event: any) => {
        event.preventDefault()
        if (error) clearError()
        const config = {
            header: false,
            skipEmptyLines: true,
            complete: (results: any) => {
                // setLoading(true)
                const csvItems = results.data
                const formattedCsvItems: Array<string> = []
                // Validate csv items if its a valid mobile number format
                // and cleanup to a default local format
                for (let i = 0; i < csvItems.length; i++) {
                    let csvItem = csvItems[i][0] // NOTE: This might need to be refactor as this assumes that the index 0 is the mobilenumber
                    if (
                        !mobileNumberValidation(csvItem).isMobileNumberFormat()
                    ) {
                        // Check if csvitem is valid mobile number
                        toast.error(toastContent.numberformatError)
                        return
                    }
                    // If csvitem is valid mobile number
                    // then check if the valid mobile number is formatted with intdialcode
                    // if true, replace +63 with 0.
                    if (
                        mobileNumberValidation(
                            csvItem
                        ).isInternationalDialCode()
                    ) {
                        csvItem = csvItem.replace('+63', '0')
                    }
                    // Push csvitem to new array that will be set on phoneno state
                    formattedCsvItems.push(csvItem)
                }
                setPhoneNo(formattedCsvItems)
                setFile(true)
            }
        }
        Array.from(event.dataTransfer?.files || event.target.files).forEach(
            async (file: any) => {
                if (
                    file.type === 'text/csv' ||
                    file.type === 'application/vnd.ms-excel'
                ) {
                    const num = await file.text()
                    Papa.parse(num, config)
                } else {
                    initError()
                    toast.error(toastContent.csvError)
                    return false
                }
            }
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-header"
            >
                <StyledHeader>
                    <ModalHeader id="modal-header" onClose={handleClose}>
                        {modalContent.modalTitle}
                    </ModalHeader>
                </StyledHeader>
                <StyledDialog>
                    <StyledTypography>
                        <Typography variant="h5">
                            {modalContent.modalTitle}
                        </Typography>
                    </StyledTypography>
                    <StyledTypography>
                        <Typography variant="h6" fontWeight="normal">
                            {modalContent.modalDetails}
                        </Typography>
                    </StyledTypography>
                    {file ? (
                        <StyledTypography>
                            <Typography variant="h5">
                                {modalContent.recipientTitle}
                            </Typography>
                            <Typography variant="h6" fontWeight="normal">
                                {modalContent.recipientDetails + phoneNo.length}
                            </Typography>
                        </StyledTypography>
                    ) : null}
                    <StyledBackground>
                        <FileDrop onTargetClick={onTargetClick}>
                            <StyledFileDrop
                                onDragOver={(e) => {
                                    e.preventDefault()
                                }}
                                onDrop={handleFile}
                            >
                                <input
                                    hidden
                                    onChange={handleFile}
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".csv"
                                />

                                {!file ? (
                                    <StyledDropzone>
                                        <MenuBookOutlined />
                                        <Typography
                                            variant="h6"
                                            fontWeight="normal"
                                        >
                                            {modalContent.dropzoneDetails}
                                        </Typography>
                                    </StyledDropzone>
                                ) : (
                                    <Grid container spacing={1}>
                                        {phoneNo.map((number, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                md={3}
                                                gridAutoRows="auto"
                                            >
                                                <StyledItem>
                                                    {number}
                                                </StyledItem>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </StyledFileDrop>
                        </FileDrop>
                    </StyledBackground>
                </StyledDialog>
                <StyledFooter>
                    <Grid item md={12} justifyContent="flex-end">
                        <StyledAddRecipients>
                            <CommonButton onClick={handleClose} color="inherit">
                                {content.cancel}
                            </CommonButton>
                            &nbsp; &nbsp;
                            {file ? (
                                <CommonButton
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {content.addRecipient}
                                </CommonButton>
                            ) : (
                                <CommonButton disabled>
                                    {content.addRecipient}
                                </CommonButton>
                            )}
                        </StyledAddRecipients>
                    </Grid>
                </StyledFooter>
            </Dialog>
        </>
    )
}
